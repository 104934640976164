<template>
  <CardTitle>
    {{ t('Stocks') }}
  </CardTitle>
  <StocksReport
    v-if="props.product.id"
    :product="props.product"
    storage-prefix="productCard.stocksList.report"
    without-search
  >
    <template
      v-if="canCreateInventory"
      #batch-actions="{ rows, refresh }"
    >
      <ConfirmsAction
        should-prompt
        @confirmed="enqueueInventory(rows, refresh)"
      >
        <template #activator="{ prompt }">
          <QBtn
            class="bg-white text-black"
            :loading="hasProgress('createInventory')"
            @click="prompt()"
          >
            {{ t('Create Inventory') }}
          </QBtn>
        </template>
      </ConfirmsAction>
      <ProductStocksChangeBatchDialog
        :product="product"
        :editing-stocks="rows"
        :cached-batches="cachedBatches"
        #="{ open }"
        @done="refresh(); emit('batch-changed')"
        @cache-batches="cacheBatches"
      >
        <QBtn
          class="bg-white text-black q-ml-sm"
          @click="open"
        >
          {{ t('Change Batch') }}
        </QBtn>
      </ProductStocksChangeBatchDialog>
    </template>
  </StocksReport>
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import ConfirmsAction from '@/components/ConfirmsAction.vue';
import useCan from '@/composables/useCan';
import useErrorHandling from '@/composables/useErrorHandling';
import useProgressHandling from '@/composables/useProgressHandling';
import type {
  Batch,
  MutationEnqueueInventoryArgs,
  Product,
  Scalars,
  StockReportRow,
} from '@/graphql/types';
import { Ability } from '@/types/backend';
import ProductStocksChangeBatchDialog
  from '@/views/Products/ProductEdit/ProductStocksChangeBatchDialog.vue';
import { gql, useClientHandle } from '@urql/vue';
import * as R from 'ramda';
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import StocksReport from '@/views/Storage/StocksReport.vue';

const { t } = useI18n();

const { progressStarted, hasProgress } = useProgressHandling<'createInventory'>();

const { fillErrorsFromGraphQLError } = useErrorHandling();

const { client: urql } = useClientHandle();

const props = defineProps<{
  product: Product;
}>();

const emit = defineEmits<{
  'batch-changed': [];
}>();

async function enqueueInventory(stocks: StockReportRow[], refresh: () => void): Promise<void> {
  const done = progressStarted('createInventory');
  const mutation = gql`
    mutation EnqueueInventory($storageId: ID!, $productPacksIds: [ID!]!) {
      enqueueInventory(storageId: $storageId, productPacksIds: $productPacksIds) { id }
    }
  `;

  const results = await Promise.all(
    Object.entries(R.groupBy(s => s.storageId!, stocks))
      .map(([storageId, s]) => urql.mutation<unknown, MutationEnqueueInventoryArgs>(
        mutation,
        {
          storageId,
          productPacksIds: s.map(s => s.productPackId!),
        },
      )),
  );

  for (const { error } of results) {
    if (error) {
      fillErrorsFromGraphQLError(error);
    }
  }

  refresh();
  done();
}

const canCreateInventory = useCan(Ability.CreateInventories);

const cachedBatches = reactive(new Map<Scalars['ID'], Batch>());

function cacheBatches(bs: Batch[]) {
  for (const b of bs) {
    cachedBatches.set(b.id, b);
  }
}

</script>

<i18n lang="yaml">
ru:
  Change Batch: Изменить партию
  Locked Amount: Блокировка

en:
  Change Batch: Change Batch
  Locked Amount: Locked Amount
</i18n>
