<template>
  <div
    class="column no-wrap q-col-gutter-sm virtual-keyboard-wrapper q-mt-none"
    @focusin="handleFocusin"
  >
    <div class="row no-wrap q-col-gutter-sm">
      <NumericKey char="1" />
      <NumericKey char="2" />
      <NumericKey char="3" />
    </div>

    <div class="row no-wrap q-col-gutter-sm">
      <NumericKey char="4" />
      <NumericKey char="5" />
      <NumericKey char="6" />
    </div>

    <div class="row no-wrap q-col-gutter-sm">
      <NumericKey char="7" />
      <NumericKey char="8" />
      <NumericKey char="9" />
    </div>

    <div class="row no-wrap q-col-gutter-sm">
      <CommaKey v-if="showCommaKey" />
      <EnterKey v-if="showEnterKey" />
      <NumericKey char="0" />
      <BackspaceKey />
    </div>
  </div>
</template>

<script setup lang="ts">

import useSpeaker from '@/composables/useSpeaker';
import { emulateBackspace, emulateEnter, emulateStringInput } from '@/helpers/inputEmulator';
import type { QBtnProps } from 'quasar';
import { QBtn } from 'quasar';
import { type Component, h, ref } from 'vue';

const speaker = useSpeaker();

defineProps<{
  showCommaKey?: boolean;
  showEnterKey?: boolean;
}>();

const emit = defineEmits<{
  input: [key: string];
  backspace: [];
  enter: [];
  shown: [];
}>();

// region Components

// noinspection LocalVariableNamingConventionJS
const Key: Component<{ invisible?: boolean } & QBtnProps> = ({ invisible }, { attrs, emit }) =>
  h('div', { class: 'col' }, invisible ? '' : h(QBtn, {
    // disable: props.disable,
    size:    'xl',
    onClick: () => emit('click'),
    ...attrs,
    class: 'virtual-keyboard-key full-width',
  }));

Key.emits = ['click'];

// noinspection LocalVariableNamingConventionJS
const NumericKey: Component<{ char: `${number}` }> = ({ char }) => h(Key, {
  icon: `mdi-numeric-${char}`,
  onClick: () => handleCharInput(String(char)),
});
// noinspection LocalVariableNamingConventionJS
const EnterKey = () => h(Key, {
  icon:    'mdi-keyboard-return',
  onClick: handleEnterInput,
});
// noinspection LocalVariableNamingConventionJS
const BackspaceKey = () => h(Key, {
  icon:    'mdi-backspace',
  onClick: handleBackspaceInput,
});
// noinspection LocalVariableNamingConventionJS
const CommaKey = () => h(Key, {
  icon:    'mdi-decimal-comma',
  onClick: () => handleCharInput('.'),
});

// endregion

// При фокусе в каком-либо поле ввода клавиатура должна работать с ним.
const focusedInput = ref<HTMLInputElement | null>(null);

function handleFocusin(e: FocusEvent) {
  if (e.relatedTarget instanceof HTMLInputElement) {
    focusedInput.value = e.relatedTarget;
  }
}

function handleCharInput(key: string): void {
  speaker.speak(key);
  if (focusedInput.value) {
    emulateStringInput(focusedInput.value, key);
    focusedInput.value = null;
  } else {
    emit('input', key);
  }
}

function handleEnterInput() {
  if (focusedInput.value) {
    emulateEnter(focusedInput.value);
    focusedInput.value.blur();
    focusedInput.value = null;
  } else {
    emit('enter');
  }
}

function handleBackspaceInput() {
  if (focusedInput.value) {
    emulateBackspace(focusedInput.value);
    focusedInput.value = null;
  } else {
    emit('backspace');
  }
}

</script>

<style lang="scss">

body.body--light .virtual-keyboard-wrapper {
  background-color: $grey-2;
}

body.body--dark .virtual-keyboard-wrapper {
  background-color: $dark-page;

  .virtual-keyboard-key {
    background-color: $dark;
    color: white;
  }
}

</style>
