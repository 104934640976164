<template>
  <QCardSection>
    <div class="row items-center q-mb-md wrap-xs">
      <QSelect
        v-model="areaKindFrom"
        :label="t('Cells Area')"
        :options="areaKinds"
        :option-label="areaKindLabel"
        bottom-slots
        :hint="t('Cell Area From Hint')"
        class="col-12 col-sm"
      />
      <QIcon
        name="mdi-arrow-right-thin"
        size="md"
        class="col-shrink gt-xs q-mx-md"
      />
      <StoragesSelect
        v-model="storageTo"
        :hint="t('Storage To Hint')"
        class="col-12 col-sm"
      />
    </div>

    <QInput
      v-model="products"
      type="textarea"
      stack-label
      bottom-slots
      outlined
      :label="t('Products')"
      class="q-mt-sm q-mb-md"
    >
      <template #hint>
        {{ t('Products Hint') }}
      </template>
    </QInput>

    <QInput
      v-if="productsErrors"
      :model-value="productsErrors"
      readonly
      error
      no-error-icon
      type="textarea"
      outlined
      :label="t('Errors')"
      class="q-mt-xl"
    >
      <template #append>
        <CopyToClipboardButton
          :text="productsErrors"
          flat
          round
          dense
        />
      </template>
    </QInput>
  </QCardSection>

  <PrimaryErrorBanner animated />

  <QCardActions :vertical="screen.xs">
    <QBtn
      icon="mdi-cancel"
      :label="t('Cancel')"
      @click="emit('close')"
    />

    <QSpace v-if="screen.gt.xs" />

    <QBtn
      icon="mdi-check-all"
      :label="t('Validate')"
      :disable="!canCreate"
      :loading="validating"
      color="positive"
      @click="validate"
    />
    <QBtn
      icon="mdi-plus"
      :label="t('Create')"
      :disable="!canCreate"
      :loading="creating"
      color="primary"
      @click="create"
    />
  </QCardActions>
</template>

<script setup lang="ts">

import CopyToClipboardButton from '@/components/CopyToClipboardButton.vue';
import StoragesSelect from '@/components/StoragesSelect.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import {
  type Cell,
  CellsAreaKindEnum,
  type Container,
  type MutationCreateTaskMoveProductToSpecifiedStorageArgs,
  type TransferTask,
} from '@/graphql/types';
import { gql, useMutation } from '@urql/vue';
import { useQuasar } from 'quasar';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { fillErrorsFromGraphQLError, errorsFor, PrimaryErrorBanner, clearErrors } = useErrorHandling();

const { t } = useI18n();

const { screen } = useQuasar();

const emit = defineEmits<{
  close: [];
  create: [];
}>();

const products = ref('');

const productsErrors = ref('');

const areaKindFrom = ref(CellsAreaKindEnum.SELECTION);

const areaKinds = Object.values(CellsAreaKindEnum);

function areaKindLabel(v: CellsAreaKindEnum) {
  return t(`cellsAreaKind.${v}`);
}

const storageTo = ref<Cell | Container | null>(null);

const canCreate = computed(() => storageTo.value && products.value.length > 0);

async function validate() {
  clearErrors();
  productsErrors.value = '';

  const { error } = await doValidate({
    areaKindFrom: areaKindFrom.value,
    storageToId: storageTo.value!.id,
    products:  products.value,
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
    fillProductsErrors(errorsFor('products'));
    return;
  }
}

async function create() {
  clearErrors();
  productsErrors.value = '';

  const { error } = await doCreate({
    areaKindFrom: areaKindFrom.value,
    storageToId: storageTo.value!.id,
    products:  products.value,
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
    fillProductsErrors(errorsFor('products'));
    return;
  }

  emit('create');
}

function fillProductsErrors(errors: Record<string, string[]>) {
  const errorLines: string[] = [];

  products.value = products.value
    .split(/\r?\n/)
    .map((line, index) => {
      if (index in errors) {
        const error = Object.values(errors[index]).flat()[0];
        errorLines.push(`${error}\t${line}`);
        return null;
      }
      return line;
    })
    .filter(line => line !== null)
    .join('\n');

  productsErrors.value = errorLines.join('\n');
}

const {
  fetching: validating,
  executeMutation: doValidate,
} = useMutation<{ task: TransferTask }, Omit<MutationCreateTaskMoveProductToSpecifiedStorageArgs, 'validateOnly'>>(gql`
  mutation ValidateTaskMoveProductToSpecifiedStorage(
    $areaKindFrom: CellsAreaKindEnum!,
    $storageToId: ID!,
    $products: String!,
  ) {
    task: createTaskMoveProductToSpecifiedStorage(
      validateOnly: true,
      areaKindFrom: $areaKindFrom,
      storageToId: $storageToId,
      products: $products,
    ) {
      id
    }
  }
`);

const {
  fetching: creating,
  executeMutation: doCreate,
} = useMutation<{ task: TransferTask }, Omit<MutationCreateTaskMoveProductToSpecifiedStorageArgs, 'validateOnly'>>(gql`
  mutation CreateTaskMoveProductToSpecifiedStorage(
    $areaKindFrom: CellsAreaKindEnum!,
    $storageToId: ID!,
    $products: String!,
  ) {
    task: createTaskMoveProductToSpecifiedStorage(
      validateOnly: false,
      areaKindFrom: $areaKindFrom,
      storageToId: $storageToId,
      products: $products,
    ) {
      id
    }
  }
`);
</script>

<i18n lang="yaml">
ru:
  Cell Area From Hint: Откуда перемещать товары
  Storage To Hint: Куда перемещать товары
  Products Hint: >
    Артикул товара и количество, разделенные табуляцией.
    Количество не обязательно, по умолчанию 1 штука
  Example: Пример
  Validate: Проверить
  Errors: Ошибки

en:
  Cell Area From Hint: From where to transfer products
  Storage To Hint: To where to transfer products
  Products Hint: >
    Product SKU and amount, separated by tabs.
    Amount is optional, 1 piece by default
  Example: Example
  Validate: Validate
  Errors: Errors
</i18n>
