<template>
  <FilterableTable
    ref="itemsTable"
    :fields="itemsHeaders"
    :rows="task.items"
    :item-meets-search-string="null"
    :storage-prefix="'transferTaskCard.' + props.task.id"
    show-line-numbers
    shrink
    no-loading-columns
  >
    <template #body-cell-sku="column">
      <BodyCellLink
        :column="column"
        :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: column.row.productPack.product.id } }"
        variant="link"
      />
    </template>
  </FilterableTable>
</template>

<script setup lang="ts">

import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import type {
  TransferTask, TransferTaskItem,
} from '@/graphql/types';
import type { QTableColumn } from 'quasar';
import { useI18n } from 'vue-i18n';
import {  ref } from 'vue';
import itemAmount from '@/helpers/itemAmount';
import FilterableTable from '@/components/FilterableTable.vue';
import ROUTES from '@/router/routeNames';
import * as R from 'ramda';

const { t } = useI18n();

const props = defineProps<{
  task: TransferTask;
}>();

const itemsHeaders: QTableColumn<TransferTaskItem>[] = [
  {
    label: t('SKU'),
    name:  'sku',
    field: i => i.productPack.product.sku,
    align: 'left',
  },
  {
    label: t('Name'),
    name:  'productPack.product.name',
    field: i => i.productPack.product.name,
    align: 'left',
  },
  {
    label: t('Origin Cell/Container'),
    name:  'storageFrom',
    field: i => i.storageFrom.name,
    align: 'left',
  },
  {
    label: t('Target Cell/Container'),
    name:  'storageTo',
    field: i => i.storageTo?.name,
    align: 'left',
  },
  {
    label: t('Amount'),
    name:  'itemAmount',
    field: i => itemAmount({ amount: i.plannedAmount, productPack: i.productPack }),
  },
  {
    label: t('Transferred'),
    name:  'transferredAmount',
    field: i => itemAmount({ amount: i.transferredAmount, productPack: i.productPack }),
  },
  {
    label: t('Not Transferred'),
    name: 'notTransferredAmount',
    field: i => itemAmount(
      { amount: i.plannedAmount - i.transferredAmount, productPack: i.productPack }
    ),
  },
  {
    label: t('Reserved'),
    name: 'reserved',
    field: i => itemAmount(
      { amount: R.min(i.availableAmount, i.plannedAmount - i.transferredAmount), productPack: i.productPack }
    ),
  },
];

const itemsTable = ref();

</script>
