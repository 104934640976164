<template>
  <PrimaryErrorBanner />
  <BigLoading v-if="fetching" />
  <template v-else-if="cell">
    <HorizontalPropsList v-slot="{ item }">
      <component
        :is="item"
        :caption="t('Name')"
        class="text-no-wrap"
      >
        {{ cell.name }}
      </component>
      <component
        :is="item"
        :caption="t('Cell ID')"
      >
        {{ cell.id }}
      </component>
      <component
        :is="item"
        v-if="cell.cellsArea"
        :caption="t('Area')"
      >
        <QChip
          dense
          class="q-ml-none"
          :class="forCellAreaKind(cell.cellsArea.kind)"
        >
          {{ cell.cellsArea.name }}
        </QChip>
      </component>
      <component
        :is="item"
        :caption="t('Barcode')"
      >
        {{ cell.barcode }}
      </component>
    </HorizontalPropsList>

    <div class="q-pa-sm">
      <QInput
        :ref="e => { searchInput = e?.getNativeElement(); }"
        v-model="searchString"
        :label="t('Search Products')"
        dense
        clearable
        autocomplete="off"
      >
        <template #prepend>
          <QIcon name="mdi-magnify" />
        </template>
      </QInput>
    </div>

    <QList separator>
      <QItem v-if="stocksFetching || containersLoading">
        <QItemSection avatar>
          <QSkeleton type="QAvatar" />
        </QItemSection>
        <QItemSection>
          <QItemLabel>
            <QSkeleton
              type="text"
              width="50px"
            />
          </QItemLabel>
          <QItemLabel>
            <QSkeleton type="text" />
          </QItemLabel>
        </QItemSection>
        <QItemSection
          top
          side
        >
          <QSkeleton
            type="text"
            width="40px"
          />
        </QItemSection>
      </QItem>
      <template v-else>
        <QItem
          v-for="c in containers"
          :key="`container_${c.id}`"
          :to="{ name: ROUTES.CONTAINERS_EDIT, params: { id: c.id } }"
          class="list-item-default-height"
        >
          <QItemSection avatar>
            <QAvatar icon="mdi-shipping-pallet" />
          </QItemSection>
          <QItemSection>
            <QItemLabel>
              {{ c.name }}
            </QItemLabel>
            <QItemLabel>
              {{ c.barcode }}
            </QItemLabel>
          </QItemSection>
        </QItem>
        <ProductListItem
          v-for="stock in stocks"
          :key="`storageUnit_${stock.storageUnit.id}`"
          :product="stock.storageUnit.productPack.product"
          clickable
          @click="editingProductId = stock.storageUnit.productPack.product.id"
        >
          <template #top-right>
            {{ stock.amount }} {{ stock.storageUnit.productPack.measurementUnit.shortName }}
            <template v-if="stock.lockedAmount">
              ({{ t('lock {amount}', { amount: stock.lockedAmount }) }})
            </template>
          </template>
        </ProductListItem>
      </template>
    </QList>
    <ProductEdit
      v-if="editingProductId"
      v-model:product-id="editingProductId"
    />
    <Teleport to="#teleport-target-buttons-row">
      <ButtonsRow
        v-slot="{ buttonProps }"
        v2
      >
        <PrintBtn
          v-bind="buttonProps"
          @click="print"
        />
        <EnqueueBtn
          v-bind="buttonProps"
          @click="enqueue"
        />
        <PrintDialog
          v-slot="{ open }"
          v-model:barcode-type="barcodeType"
          template-name="cell-barcode"
          use-count
          :title="t('Print', {size: 1})"
          :instances="[{count: printCount, params:{ cellId: cell.id, barcodeType }}]"
          @printer-changed="selectedPrinter = $event"
          @count-changed="printCount=$event"
        >
          <QBtn
            v-bind="buttonProps"
            icon="mdi-cog"
            @click="open()"
          >
            {{ t('Print settings') }}
          </QBtn>
        </PrintDialog>
      </ButtonsRow>
    </Teleport>
  </template>
  <BaseAlert
    v-else
    type="info"
  >
    {{ t('Cell not found') }}
  </BaseAlert>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import BigLoading from '@/components/BigLoading.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import PrintDialog from '@/components/PrintDialog.vue';
import ProductListItem from '@/components/ProductListItem.vue';
import useDocumentsPrinting from '@/composables/useDocumentsPrinting';
import useErrorHandling from '@/composables/useErrorHandling';
import { PrintBarcodeTypeEnum } from '@/graphql/types';
import type {
  Cell,
  Container,
  QueryCellArgs,
  QueryContainersByStorageArgs,
  QueryStocksInStorageArgs,
  Scalars,
  Stock,
} from '@/graphql/types';
import { forCellAreaKind } from '@/helpers/badgeColors';
import ProductEdit from '@/views/CellEditMobile/ProductEdit.vue';
import HorizontalPropsList from '@/views/Mobile/HorizontalPropsList.vue';
import useDocumentsPrintingState from '@/views/useDocumentsPrintingState';
import { gql, useQuery } from '@urql/vue';
import { onStartTyping, useFocus } from '@vueuse/core';
import { QItemLabel, QItemSection } from 'quasar';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

// noinspection LocalVariableNamingConventionJS
const { fillErrorsFromGraphQLError, PrimaryErrorBanner } = useErrorHandling();

const props = defineProps<{
  id: Scalars['ID'];
}>();

const printCount = ref(1);

const { data, error, fetching } = useQuery<{
  cell: Cell;
}, QueryCellArgs>({
  query:     gql`
    query GetCellForMobileEdit($id: ID!) { cell(id: $id) {
      id
      name
      cellsArea { id name kind }
      barcode
    } }
  `,
  variables: computed(() => ({ id: props.id })),
});
watch(error, fillErrorsFromGraphQLError);
const cell = computed(() => data.value?.cell ?? null);

const searchInput = ref<HTMLInputElement>();

const { focused } = useFocus(searchInput);

onStartTyping(() => {
  if (!focused.value) {
    focused.value = true;
  }
});

const searchString = ref('');

const { data: stocksData, fetching: stocksFetching } = useQuery<{ stocks: Stock[] }, QueryStocksInStorageArgs>({
  query: gql`
    query GetStocksForCellCard($storageId: ID!) {
      stocks: stocksInStorage(storageId: $storageId) {
        amount
        lockedAmount
        storageUnit {
          id
          batch { name }
          productPack {
            barcodes { barcode }
            product {
              id
              sku
              name
              photos { id url(size: SMALL) }
            }
            measurementUnit { id shortName name }
            minMeasurementUnit { id shortName }
            quantityInMinMeasurementUnits
          }
        }
      }
    }
  `,
  variables: computed(() => ({
    storageId: props.id,
  })),
});

function stocksFilter(stock: Stock): boolean {
  if (!searchString.value) {
    return true;
  }

  const pack = stock.storageUnit.productPack;
  return [pack.product.sku, pack.product.name]
    .some(w => w.toLocaleLowerCase().includes(searchString.value.toLocaleLowerCase()))
    || pack.barcodes.some(b => b.barcode === searchString.value);
}

const stocks = computed(() => (stocksData.value?.stocks ?? []).filter(stocksFilter));

const editingProductId = ref<Scalars['ID'] | null>(null);

const {
  data: containersData,
  fetching: containersLoading,
} = useQuery<{ containers: Container[] }, QueryContainersByStorageArgs>({
  query: gql`
    query GetContainersForCellStocksList($storageId: ID!) {
      containers: containersByStorage(storageId: $storageId) { id name barcode }
    }
  `,
  variables: computed(() => ({ storageId: props.id })),
});

const containers = computed(() => containersData.value?.containers ?? []);

const printState = useDocumentsPrintingState();

const barcodeType = ref(printState.value.lastCellBarcodeType ?? PrintBarcodeTypeEnum.BARCODE);

// noinspection LocalVariableNamingConventionJS
const {
  selectedPrinter,
  selectedPrinterPaperLayout,
  printNow,
  enqueuePrint,
  PrintBtn,
  EnqueueBtn,
} = useDocumentsPrinting();

async function print() {
  await printNow('cell-barcode', [{
    count: 1,
    params: {
      cellId:      cell.value!.id,
      barcodeType: barcodeType.value,
    }
  }], selectedPrinterPaperLayout.value!);
}

async function enqueue() {
  await enqueuePrint('cell-barcode', [{
    count: 1,
    params: {
      cellId:      cell.value!.id,
      barcodeType: barcodeType.value,
    }
  }], selectedPrinterPaperLayout.value!);
}

</script>

<i18n lang="yaml" src="../plugins/i18n/sharedMessages/printing.yaml"></i18n>

<i18n lang="yaml">
ru:
  Cell not found: Ячейка не найдена
  Cell ID: Код
  Area: Зона
  Search Products: Поиск товаров
  lock {amount}: блок {amount}
en:
  Cell not found: Cell not found
  Cell ID: ID
  Area: Area
  Search Products: Search Products
  lock {amount}: lock {amount}
</i18n>
