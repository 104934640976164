<template>
  <BaseAlert
    v-if="getPrimaryError()"
    type="error"
  >
    {{ getPrimaryError() }}
  </BaseAlert>
  <div class="column q-pa-sm">
    <QSelect
      v-model="productPack.measurementUnit"
      :options="measurementUnits"
      option-label="name"
      :loading="unitsLoading"
      :label="t('Measurement Unit')"
      :error="v.measurementUnit.$error"
      :error-message="v.measurementUnit.$errors[0]?.$message"
    />
    <QSelect
      v-model="productPack.smallerProductPack"
      :disable="!canConsistOfOtherPacks"
      :options="productPacks"
      :option-label="productPackTitle"
      :loading="unitsLoading"
      :label="t('Consists Of')"
      :error="v.smallerProductPack.$error"
      :error-message="v.smallerProductPack.$errors[0]?.$message"
    />
    <VirtualKeyboardPolicyContext #="{ policy, shouldShowToggle }">
      <QInput
        ref="quantityField"
        v-model.number="productPack.quantity"
        :disable="!canConsistOfOtherPacks"
        :label="t('Quantity')"
        type="number"
        min="1"
        :rules="[positiveFloatRule]"
        :virtualkeyboardpolicy="policy"
      >
        <template
          v-if="shouldShowToggle"
          #append
        >
          <VirtualKeyboardToggleBtn @toggle="quantityField.focus();" />
        </template>
      </QInput>
    </VirtualKeyboardPolicyContext>
  </div>

  <Teleport
    :to="buttonsPanel"
    :disabled="!buttonsPanel"
  >
    <ButtonsRow
      v-slot="{ buttonProps }"
      v2
    >
      <QBtn
        v-bind="buttonProps"
        icon="mdi-content-save"
        :loading="creatingProductPack"
        :disable="v.$invalid"
        @click="saveNewProductPack()"
      >
        {{ t('Save') }}
      </QBtn>
      <QBtn
        v-bind="buttonProps"
        icon="mdi-cancel"
        :disable="creatingProductPack"
        @click="emit('cancel')"
      >
        {{ t('Cancel') }}
      </QBtn>
    </ButtonsRow>
  </Teleport>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useValidationRules from '@/composables/useValidationRules';
import {
  VirtualKeyboardPolicyContext,
  VirtualKeyboardToggleBtn,
} from '@/composables/useVirtualKeyboardPolicy';
import ProductPackForMobileCard from '@/graphql/fragments/ProductPackForMobileCard';
import type { MeasurementUnit, Mutation, MutationCreateProductPackArgs, Product, ProductPack } from '@/graphql/types';
import { MeasurementUnitClassEnum } from '@/graphql/types';
import pickSmallerPackAndUnitForNewPack from '@/helpers/pickSmallerPackAndUnitForNewPack';
import productPackTitle from '@/helpers/productPackTitle';
import { required, requiredIf } from '@/setup/validation';
import { gql, useClientHandle, useQuery } from '@urql/vue';
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { QInput } from 'quasar';
import { computed, nextTick, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { client: urql } = useClientHandle();

const { fillErrorsFromGraphQLError, getPrimaryError, clearErrors } = useErrorHandling();

const { positiveFloatRule } = useValidationRules();

const props = defineProps<{
  product: Product;
  productPacks: ProductPack[];
  buttonsPanel?: HTMLElement | string;
}>();

const emit = defineEmits<{
  (e: 'created', productPack: ProductPack | null): void;
  (e: 'cancel'): void;
}>();

const {
  data: measurementUnitsResult,
  fetching: unitsLoading,
} = useQuery<{ measurementUnits: MeasurementUnit[] }>({
  query: gql`
    query GetMeasurementUnits { measurementUnits {
      id name shortName class
    } }
  `,
});
const measurementUnits = computed<MeasurementUnit[]>(() => {
  if (!measurementUnitsResult.value) {
    return [];
  }
  if (props.productPacks.some((pp) => pp.measurementUnit.class === MeasurementUnitClassEnum.PIECES)) {
    return measurementUnitsResult.value.measurementUnits.filter(
      (mu) => mu.class !== MeasurementUnitClassEnum.PIECES,
    );
  }

  return measurementUnitsResult.value.measurementUnits;
});

const productPack = ref<Pick<ProductPack, 'measurementUnit' | 'smallerProductPack' | 'quantity'>>({
  measurementUnit:    null!,
  smallerProductPack: null,
  quantity:           1,
});

watch(measurementUnits, units => {
  if (!productPack.value.measurementUnit && !productPack.value.smallerProductPack) {
    Object.assign(productPack.value, pickSmallerPackAndUnitForNewPack(props.productPacks, units));
  }
});

watch(() => productPack.value.measurementUnit, function measurementUnitChanged(mu): void {
  if (mu.class === MeasurementUnitClassEnum.PIECES) {
    productPack.value.smallerProductPack = null;
  }
  productPack.value.quantity = 1;
});

const canConsistOfOtherPacks = computed((): boolean => {
  return !!productPack.value.measurementUnit
    && productPack.value.measurementUnit.class !== MeasurementUnitClassEnum.PIECES;
});

const creatingProductPack = ref(false);

async function saveNewProductPack(): Promise<void> {
  creatingProductPack.value = true;

  clearErrors();

  const { error, data } = await urql.mutation<Pick<Mutation, 'createProductPack'>, MutationCreateProductPackArgs>(
    gql`
      ${ProductPackForMobileCard}
      mutation CreateProductPack($productId: ID!, $measurementUnitId: ID!, $smallerProductPackId: ID, $quantity: Float) {
        createProductPack(productId: $productId, measurementUnitId: $measurementUnitId, smallerProductPackId: $smallerProductPackId, quantity: $quantity) {
          ...ProductPackForMobileCard
        }
      }
    `,
    {
      productId:            props.product!.id,
      measurementUnitId:    productPack.value!.measurementUnit.id,
      smallerProductPackId: productPack.value!.smallerProductPack?.id,
      quantity:             productPack.value!.quantity,
    },
  );

  if (error) {
    fillErrorsFromGraphQLError(error);
  } else {
    emit('created', data!.createProductPack!);
  }

  creatingProductPack.value = false;
}

const quantityField = ref<InstanceType<typeof QInput>>();

watch(canConsistOfOtherPacks, can => {
  if (can) {
    nextTick(() => {
      quantityField.value?.select();
    });
  }
});

const v = useVuelidate<typeof productPack>({
  measurementUnit:    { required },
  smallerProductPack: {
    required: requiredIf(() => productPack.value?.measurementUnit?.class !== MeasurementUnitClassEnum.PIECES),
    measurementUnitDiffers: helpers.withMessage(
      t('Should not consist of Product Pack with same Measurement Unit'),
      (smallerPack: ProductPack, pack: ProductPack) => !smallerPack || smallerPack.measurementUnit.class !== pack.measurementUnit?.class,
    ),
  },
}, productPack, { $autoDirty: true });

</script>

<i18n lang="yaml">
ru:
  Should not consist of Product Pack with same Measurement Unit: >
    Должна состоять из упаковок с другой единицей упаковки

en:
  Should not consist of Product Pack with same Measurement Unit: >
    Should not consist of Product Pack with same Measurement Unit
</i18n>
