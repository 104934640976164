<template>
  <template v-if="canImport">
    <QDialog
      v-model="showUploadFileDialog"
      :persistent="progress.validation"
    >
      <QCard>
        <CardTitle>
          {{ t('Upload File') }}
        </CardTitle>

        <QSeparator />

        <QCardSection>
          <div v-if="validationResult === null">
            <BaseAlert
              v-if="!progress.validation && $slots['info-for-upload-file']"
              rounded
              type="info"
            >
              <slot name="info-for-upload-file" />
            </BaseAlert>

            <QFile
              v-model="importFile"
              :disable="progress.validation"
              :label="t('Select a File')"
              :accept="excelFormats"
              clearable
            >
              <template #before>
                <QIcon name="mdi-file-excel" />
              </template>
              <template #file="{ file }">
                {{ file.name }} ({{ fileSize(file.size) }})
              </template>
            </QFile>
          </div>

          <BaseAlert
            v-if="getPrimaryError()"
            type="error"
            rounded
          >
            {{ getPrimaryError() }}
          </BaseAlert>

          <BaseAlert
            v-if="validationResult && validationResult.isValid === false"
            type="error"
            rounded
          >
            {{ validationResult.validationErrorMessage }}<!--
            --><span v-if="validationResult.rowsCount">: </span>
            <slot
              v-if="validationResult.rowsCount"
              v-bind="validationResult"
              name="row-with-error-message"
            />
            <QBtn
              v-if="validationResult.rowsCount"
              :href="validationResult.fileWithErrorsUrl"
              color="accent"
              class="mr-2"
              download
              icon="mdi-download"
            >
              {{ t('Download Failed File') }}
            </QBtn>
          </BaseAlert>

          <BaseAlert
            v-if="validationResult && validationResult.isValid"
            type="success"
            rounded
          >
            <slot
              v-bind="validationResult"
              name="validation-success"
            />
          </BaseAlert>
        </QCardSection>

        <QSeparator />

        <QCardActions>
          <QBtn
            v-if="!validationResult"
            :disabled="!importFile"
            :loading="progress.validation"
            color="primary"
            class="mr-2"
            @click="validateFile"
          >
            <span v-if="progress.validation">
              {{ t('Validating File') }}
            </span>
            <span v-else>
              {{ t('Validate File') }}
            </span>
          </QBtn>

          <QBtn
            v-else
            :disabled="!validationResult || !validationResult.isValid"
            :loading="progress.import"
            color="success"
            @click="importFromFile"
          >
            <slot name="import-btn-title" />
          </QBtn>

          <QBtn
            :disable="progress.validation"
            @click="showUploadFileDialog = false"
          >
            {{ t('Cancel') }}
          </QBtn>
        </QCardActions>
      </QCard>
    </QDialog>

    <slot
      v-bind="{
        start: openDialog,
      }"
    >
      <QBtn
        color="success"
        icon="mdi-upload"
        :label="t('Upload File')"
        v-bind="$attrs"
        @click="openDialog"
      />
    </slot>
  </template>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import {
  ExcelImportTypeEnum,
  type ExcelValidationResult,
  type MutationImportExcelFileArgs,
  type MutationValidateExcelImportFileArgs,
} from '@/graphql/types';
import fileSize from '@/helpers/fileSize';
import useStore from '@/stores/root';
import type { ReportOptions } from '@/types/reports';
import { useMutation } from '@urql/vue';
import type { DocumentNode } from 'graphql';
import { QFile, useQuasar } from 'quasar';
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { fillErrorsFromGraphQLError, clearErrors, getPrimaryError } = useErrorHandling();

const progress = reactive({
  validation: false,
  import: false,
});

const props = defineProps<{
  type: ExcelImportTypeEnum;
  options?: ReportOptions;
  mutationValidate: DocumentNode;
  mutationImport: DocumentNode;
  successNotification: (n: number) => string;
}>();

const emit = defineEmits<{
  (e: 'success'): void;
  (e: 'close'): void;
}>();

const showUploadFileDialog = ref(false);

const validationResult = ref<ExcelValidationResult | null>(null);

const importFile = ref<File | null>(null);

const excelFormats = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
].join(', ');

const validateFileMutation = useMutation<
  { validationResult: ExcelValidationResult },
  MutationValidateExcelImportFileArgs
>(props.mutationValidate);

const store = useStore();

const canImport = computed(() => {
  if (!store.canImport) {
    return false;
  }

  if (store.moySkladIntegrationEnabled) {
    return [
      ExcelImportTypeEnum.CELLS,
      ExcelImportTypeEnum.CONTAINERS,
      ExcelImportTypeEnum.PRODUCTS,
      ExcelImportTypeEnum.PRODUCTS_BARCODES,
      ExcelImportTypeEnum.TRANSFERS,
    ].includes(props.type);
  }

  return true;
});

async function validateFile(): Promise<void> {
  clearErrors();

  progress.validation = true;
  const { error, data } = await validateFileMutation.executeMutation({
    type: props.type,
    file: importFile.value!,
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
  } else {
    validationResult.value = data!.validationResult;
  }

  progress.validation = false;
}

const q = useQuasar();

const importMutation = useMutation<
  { importedCount: number },
  MutationImportExcelFileArgs
>(props.mutationImport);

async function importFromFile(): Promise<void> {
  clearErrors();

  progress.import = true;

  const { error, data } = await importMutation.executeMutation({
    type: props.type,
    file: importFile.value!,
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
  } else {
    emit('success');
    emit('close');
    q.notify({
      message:  props.successNotification(data!.importedCount),
      type:     'positive',
      position: 'top',
    });
    showUploadFileDialog.value = false;
  }

  progress.import = false;
}

watch(showUploadFileDialog, isOpen => {
  validationResult.value = null;
  importFile.value = null;
  clearErrors();
  if (!isOpen) {
    emit('close');
  }
});

function openDialog() {
  showUploadFileDialog.value = true;
}

</script>

<i18n lang="yaml">
ru:
  Upload File: Загрузить файл
  Select a File: Выберите файл
  Validate File: Проверить файл
  Validating File: Проверка файла
  Download Failed File: Скачать файл с ошибками

en:
  Upload File: Upload File
  Select a File: Select a File
  Validate File: Validate File
  Validating File: Validating File
  Download Failed File: Download Failed File
</i18n>
