<template>
  <QItem
    class="list-item-default-height"
    clickable
    @click="handleClick"
  >
    <QItemSection class="dense-labels no-wrap">
      <QItemLabel class="row no-wrap">
        <span class="text-no-wrap ellipsis">
          {{ t('# {text}', { text: props.arrival.id }) }}
        </span>

        <QSpace />

        <span>
          {{ props.arrival.storage.name }}
        </span>
      </QItemLabel>
      <QItemLabel class="row no-wrap">
        <span>
          {{ formatDate(props.arrival.createdAt, FORMATS.DATETIME) }}
        </span>

        <QSpace />

        <span v-if="props.arrival.supply?.externalId">
          {{ t('Sup. {id}', { id: props.arrival.supply.externalId }) }}
        </span>
      </QItemLabel>
      <QItemLabel class="row justify-between">
        <span>
          <QChip
            dense
            class="q-ma-none"
            :color="badgeColors.forOperationState(arrival)"
            text-color="black"
          >
            {{ t(`arrivalState.${props.arrival.state}`) }}
          </QChip>
        </span>
      </QItemLabel>
    </QItemSection>
  </QItem>
</template>

<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type { Arrival } from '@/graphql/types';
import { ArrivalStateEnum } from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import { useRouter } from 'vue-router';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const props = defineProps<{
  arrival: Arrival;
}>();

const emit = defineEmits<{
  (e: 'continue-arrival'): void;
}>();

const router = useRouter();

function handleClick() {
  if (props.arrival.state === ArrivalStateEnum.IN_PROGRESS) {
    emit('continue-arrival');
  } else {
    router.push({ name: ROUTES.ARRIVALS_EDIT, params: { id: props.arrival.id } });
  }
}

</script>

<i18n lang="yaml">
ru:
  Sup. {id}: Пост. {id}

en:
  Sup. {id}: Sup. {id}

</i18n>
