<template>
  <QBanner
    v-if="visible"
    class="items-center"
    :class="bannerClass"
    v-bind="$attrs"
    :inline-actions="dismissable"
  >
    <template #avatar>
      <QAvatar
        :size="props.large ? 'xl' : 'md'"
        square
      >
        <QIcon
          :name="icon"
          :size="props.large ? 'md' : 'sm'"
        />
      </QAvatar>
    </template>
    <slot />
    <template
      v-if="dismissable"
      #action
    >
      <QBtn
        flat
        round
        size="sm"
        icon="mdi-close-circle"
        @click="visible = false"
      />
    </template>
  </QBanner>
</template>
<script setup lang="ts">

import { computed, ref } from 'vue';

const props = defineProps<{
  type: 'error' | 'warning' | 'info' | 'success';
  icon?: string;
  dismissable?: boolean;
  invisible?: boolean;
  large?: boolean;
}>();

const visible = ref(true);

const bannerClass = computed(() => {
  if (props.invisible) {
    return 'invisible';
  }
  const classes: string[] = [];
  switch (props.type) {
    case 'error':
      classes.push('text-white', 'bg-error');
      break;
    case 'warning':
      classes.push('text-white', 'bg-orange');
      break;
    case 'info':
      classes.push('text-white', 'bg-blue');
      break;
    case 'success':
      classes.push('text-white', 'bg-green');
  }
  if (props.large) {
    classes.push('q-px-sm');
  }
  return classes;
});

const icon = computed(() => {
  if (props.icon) {
    return props.icon;
  }
  switch (props.type) {
    case 'error':
      return 'mdi-alert';
    case 'warning':
      return 'mdi-exclamation';
    case 'info':
      return 'mdi-information';
    case 'success':
      return 'mdi-check-circle';
    default:
      return '';
  }
});

</script>
