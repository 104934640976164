<template>
  <div class="column q-pa-lg justify-center">
    <div v-if="expectedStorage">
      <div class="row justify-center q-pa-mb">
        <h5 class="q-ma-none">
          {{ expectedStorage.name }}
        </h5>
      </div>
    </div>

    <GraphQLQueryScanField
      v-if="!store.storageFrom"
      :query="scanQuery"
      :variables="{ transferId: store.transfer?.id }"
      :rules="[storageMeetsRequiredRule]"
      :hint="expectedStorage ? t('Scan the above specified Storage') : t('Scan First Storage')"
      :placeholder="t('Storage')"
      :not-found-message="t('Storage not found')"
      append-icon="mdi-inbox-arrow-up"
      @scan="handleScanStorageFrom"
    />

    <Teleport to="#teleport-target-buttons-row">
      <ButtonsRow
        v-slot="{ buttonProps }"
        v2
      >
        <KeyboardToggleButton
          v-bind="buttonProps"
          :disable="keyboardDisabled"
        />
        <QBtn
          v-bind="buttonProps"
          icon="mdi-close-circle-outline"
          @click="emit('cancel')"
        >
          {{ t('Cancel') }}
        </QBtn>
        <QBtn
          v-if="store.transfer?.task"
          v-bind="buttonProps"
          icon="mdi-format-list-checks"
          @click="emit('show-task')"
        >
          {{ t('Task') }}
        </QBtn>
        <TransferHistoryButton
          v-bind="buttonProps"
          @click="emit('show-history')"
        />
      </ButtonsRow>
    </Teleport>
  </div>
</template>

<script setup lang="ts">

import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import GraphQLQueryScanField from '@/components/Mobile/GraphQLQueryScanField.vue';
import useOmniInput from '@/composables/useOmniInput';
import useSpeaker from '@/composables/useSpeaker';
import StorageForTransfer from '@/graphql/fragments/StorageForTransfer';
import type { Cell, Container, Storage } from '@/graphql/types';
import useTransferProcessStore from '@/stores/transferProcess';
import type { FunctionValidationRule } from '@/types';
import TransferHistoryButton from '@/views/Mobile/Transfer/TransferHistoryButton.vue';
import { gql } from '@urql/vue';
import { useI18n } from 'vue-i18n';

const store = useTransferProcessStore();

const speaker = useSpeaker();

const { t } = useI18n();

const props = defineProps<{
  expectedStorage: Storage | null;
  keyboardDisabled: boolean;
}>();

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'show-task'): void;
  (e: 'show-history'): void;
}>();

const scanQuery = gql`
  ${StorageForTransfer}
  query GetStorageByBarcodeForTransfer($barcode: String!) {
    storageByBarcode(barcode: $barcode) { ...StorageForTransfer }
  },
`;

function handleScanStorageFrom(storage: Container | Cell) {
  speaker.speak(t('From {name}', { name: storage.name }));
  store.storageFrom = storage;
  store.updateTransferState();
  store.availableStorages.push(storage);
}

function storageMeetsRequiredRule(storage: Storage): ReturnType<FunctionValidationRule> {
  if (props.expectedStorage && props.expectedStorage.id !== storage.id) {
    return t('The storage should match specified');
  }

  return true;
}

const { KeyboardToggleButton }  = useOmniInput({ skip: true });

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/speaking.yaml"></i18n>

<i18n lang="yaml">
ru:
  Scan First Storage: Сканируйте первое место хранения
  Move: Переместить
  Wrong amount: Неверное количество

en:
  Scan First Storage: Scan First Storage
  Move: Move
  Wrong amount: Wrong amount
</i18n>
