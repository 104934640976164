<template>
  <QDialog
    v-model="isOpen"
    :persistent="completing"
  >
    <QCard>
      <CardTitle>
        {{ t('Separate Orders?') }}
      </CardTitle>

      <QSeparator />

      <PrimaryErrorBanner animated />

      <QCardSection>
        <div class="row q-col-gutter-md">
          <div class="col-xs-6 col-md">
            <QSelect
              v-model="selectedExternalShipmentId"
              :options="shipmentIdsSelectOptions"
              :label="t('Order')"
            />
          </div>
          <div class="col-xs-6 col-md">
            <QInput
              v-model="containerToBarcode"
              :label="t('Container Barcode')"
            />
          </div>
        </div>
      </QCardSection>

      <QCardActions>
        <QBtn
          :label="t('Cancel')"
          :disable="completing"
          @click="isOpen = false"
        />
        <QBtn
          :label="t('Separate Orders')"
          :disable="!selectedExternalShipmentId || !containerToBarcode"
          color="primary"
          icon="mdi-check-all"
          :loading="completing"
          @click="separateShipments"
        />
      </QCardActions>
    </QCard>
  </QDialog>
  <QBtn
    v-if="canSeparateShipments"
    :label="t('Separate Orders')"
    @click="isOpen = true"
  />
</template>

<script setup lang="ts">

import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { gql, useMutation } from '@urql/vue';
import type { Container } from '@/graphql/types';
import useErrorHandling from '@/composables/useErrorHandling';
import CardTitle from '@/components/CardTitle.vue';

const { t } = useI18n();

// noinspection LocalVariableNamingConventionJS
const { fillErrorsFromGraphQLError, clearErrors, PrimaryErrorBanner } = useErrorHandling();

const props = defineProps<{
  container: Container;
}>();

const emit = defineEmits<{
  (e: 'completed'): void;
}>();

const isOpen = ref(false);

watch(isOpen, clearErrors);

const shipmentIdsSelectOptions = computed(() => props.container.shipments.map(s => s.externalId));

const selectedExternalShipmentId = ref<string|null>(null);
const containerToBarcode      = ref<string|null>(null);

const {
  executeMutation: separateShipment,
  fetching:        completing,
} = useMutation(gql`
  mutation TransferShipmentFromContainer($externalShipmentId: ID!, $containerFromId: ID!, $containerToBarcode: String!) {
    transferShipmentFromContainer(externalShipmentId: $externalShipmentId, containerFromId: $containerFromId, containerToBarcode: $containerToBarcode) { id }
  }
`);

const canSeparateShipments = computed(() => (props.container.orders?.length || 0) > 1);

async function separateShipments() {
  clearErrors();

  const { error } = await separateShipment({
    externalShipmentId: selectedExternalShipmentId.value,
    containerFromId:    props.container.id,
    containerToBarcode: containerToBarcode.value,
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  isOpen.value = false;

  emit('completed');
}

</script>

<i18n lang="yaml">
ru:
  Container Barcode: ШК контейнера
  Separate Orders: Разделить Заказы
  Separate Orders?: Разделить Заказы?

en:
  Container Barcode: Container Barcode
  Separate Orders: Separate Orders
  Separate Orders?: Separate Orders?

</i18n>
