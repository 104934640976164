<template>
  <SuppliesList
    :supply-route="(ret: CustomerReturn) => ({ name: ROUTES.CUSTOMER_RETURNS_EDIT, params: { id: ret.id } })"
    :extra-fields="tableFields as TableColumn<Supply>[]"
    :fixed-filters="fixedFilters"
    storage-prefix="customerReturnsList.report"
    mobile-storage-prefix="customerReturnsList.mobileReport"
  >
    <template #import-export="{ options, onSuccess }">
      <CustomerReturnsExcelImportExport
        :options="options"
        @success="onSuccess"
      />
    </template>
  </SuppliesList>
</template>

<script setup lang="ts">

import useBreadcrumbs from '@/composables/useBreadcrumbs';
import type { Customer, CustomerReturn, ReportFilterInput, Supply } from '@/graphql/types';
import ROUTES from '@/router/routeNames';
import type { TableColumn } from '@/types/reports';
import CustomerReturnsExcelImportExport
  from '@/views/Arrival/Supply/CustomerReturnsExcelImportExport.vue';
import SuppliesList from '@/views/Arrival/Supply/SuppliesList.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

useBreadcrumbs(t('Customer Returns'));

const tableFields: TableColumn<CustomerReturn>[] = [
  {
    label:  t('Customer'),
    name:   'customer',
    field:  'counterparty',
    format: (customer?: Customer) => customer?.name ?? '',
    align:  'left',
  },
  {
    label:  t('Order'),
    name:   'orderId',
    field:  s => s.orderNumber || s.customerOrder?.externalId,
    align:  'left',
  },
];

const fixedFilters = computed<ReportFilterInput[]>(() => [{
  field:    'type',
  operator: '=',
  value:    JSON.stringify('CustomerReturn' as CustomerReturn['__typename']),
}]);

</script>
