<template>
  <PrimaryErrorBanner />
  <BigLoading v-if="loading" />
  <template v-else-if="container">
    <HorizontalPropsList v-slot="{ item }">
      <component
        :is="item"
        :caption="t('Name')"
      >
        {{ container.name }}
      </component>
      <component
        :is="item"
        :caption="t('Code')"
      >
        {{ container.id }}
      </component>
      <component
        :is="item"
        v-if="container.storageCell.name"
        :caption="t('Storage Cell')"
      >
        {{ container.storageCell.name }}
      </component>
      <component
        :is="item"
        :caption="t('Barcode')"
      >
        {{ container.barcode }}
      </component>
      <component
        :is="item"
        :caption="t('Capacity')"
      >
        {{ formatWeight(container.capacity) }}
      </component>
    </HorizontalPropsList>

    <div
      v-if="container.stocks.length === 0"
      class="text-center q-pa-md"
    >
      {{ t('No products') }}
    </div>
    <FilterableList
      v-else
      :items="container.stocks"
      with-search
      :item-meets-search-string="stockMeetsSearchString"
      :debounce="0"
    >
      <template #item="{ item: stock }">
        <ProductListItem
          :key="stock.storageUnit.id"
          :product="stock.storageUnit.productPack.product"
          clickable
          @click="editingProductId = stock.storageUnit.productPack.product.id"
        >
          <template #top-right>
            {{ stock.amount }} {{ stock.storageUnit.productPack.measurementUnit.shortName }}
          </template>
        </ProductListItem>
      </template>
    </FilterableList>
    <ProductEdit
      v-if="editingProductId"
      v-model:product-id="editingProductId"
    />
    <BlurredInput @barcode="handleBarcodeInput" />
  </template>
  <EntityNotFound
    v-else
    :not-found-text="t('Container not found')"
    :back-button-route="{ name: ROUTES.CONTAINERS_LIST }"
    :back-button-text="t('Go to Containers List')"
  />
  <Teleport
    v-if="!loading && container"
    :to="buttonsPanel ?? '#teleport-target-buttons-row'"
  >
    <ButtonsRow
      v-slot="{ buttonProps }"
      v2
    >
      <PrintBtn
        v-bind="buttonProps"
        @click="print"
      />
      <EnqueueBtn
        v-bind="buttonProps"
        @click="print"
      />
      <PrintDialog
        v-slot="{ open }"
        v-model:barcode-type="barcodeType"
        template-name="container-barcode"
        use-count
        :title="container.name"
        :instances="[{count: printCount, params: { containerId: container.id, barcodeType }}]"
        @count-changed="printCount=$event"
        @printer-changed="selectedPrinter = $event"
      >
        <QBtn
          v-bind="buttonProps"
          icon="mdi-cog"
          @click="open()"
        >
          {{ t('Print settings') }}
        </QBtn>
      </PrintDialog>
      <ContainerChangeCellDialog
        :container="container"
        #="{ onClick }"
        @update:is-open="changingContainerDialog = $event"
        @update:cell="container.storageCell = $event"
      >
        <QBtn
          v-bind="buttonProps"
          icon="mdi-transfer"
          @click="onClick"
        >
          {{ t('Change Cell') }}
        </QBtn>
      </ContainerChangeCellDialog>
      <ContainerShipButton
        v-if="!noShipButton && canShipContainer(container)"
        v-bind="buttonProps"
        :containers="[container]"
        icon="mdi-truck-check"
        @completed="loadContainer"
        @update:error="$event ? fillErrorsFromGraphQLError($event) : clearErrors()"
      />
      <slot
        name="buttons"
        v-bind="{ buttonProps }"
      />
    </ButtonsRow>
  </Teleport>
</template>

<script setup lang="ts">

import BigLoading from '@/components/BigLoading.vue';
import ContainerChangeCellDialog from '@/components/ContainerChangeCellDialog.vue';
import ContainerShipButton from '@/components/ContainerShipButton.vue';
import EntityNotFound from '@/components/EntityNotFound.vue';
import FilterableList from '@/components/FilterableList.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import PrintDialog from '@/components/PrintDialog.vue';
import ProductListItem from '@/components/ProductListItem.vue';
import useDocumentsPrinting from '@/composables/useDocumentsPrinting';
import useErrorHandling from '@/composables/useErrorHandling';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import useOmniInput from '@/composables/useOmniInput';
import type { Container, QueryContainerArgs, Scalars, Stock } from '@/graphql/types';
import { PrintBarcodeTypeEnum } from '@/graphql/types';
import ROUTES from '@/router/routeNames';
import ProductEdit from '@/views/CellEditMobile/ProductEdit.vue';
import HorizontalPropsList from '@/views/Mobile/HorizontalPropsList.vue';
import useDocumentsPrintingState from '@/views/useDocumentsPrintingState';
import { gql, useClientHandle } from '@urql/vue';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import canShipContainer from '@/helpers/canShipContainer';

// noinspection LocalVariableNamingConventionJS
const { fillErrorsFromGraphQLError, PrimaryErrorBanner, clearErrors } = useErrorHandling();

const { t } = useI18n();

const { formatWeight } = useLocalizedFormatters();

const { client: urql } = useClientHandle();

const loading = ref(false);

const props = defineProps<{
  id: string;
  buttonsPanel?: HTMLElement | string;
  noShipButton?: boolean;
}>();

const emit = defineEmits<{
    (e: 'goto-container', id: string): void;
}>();

const printCount = ref(1);

const container = ref<Container>();

const editingProductId = ref<Scalars['ID'] | null>(null);

watch(() => props.id, async () => {
  loading.value = true;
  await loadContainer();
  loading.value = false;
  await loadPrinters();
}, { immediate: true });

async function loadContainer(): Promise<void> {
  const { data: containerData } = await urql.query<{ container: Container }, QueryContainerArgs>(
    gql`
      query GetContainer($id: ID!) { container(id: $id) {
        id
        name
        barcode
        capacity
        storageCell {
          id
          name
        }
        stocks {
          amount
          storage { id }
          storageUnit {
            id
            productPack {
              id
              product {
                id
                sku
                name
                photos { id url(size: SMALL) }
              }
              measurementUnit { id shortName }
            }
          }
        }
        activeSelections {
          id
        }
        kind
        deletedAt
        isEmpty
      } }
    `,
    { id: props.id! },
  );

  container.value = containerData!.container;
}

function stockMeetsSearchString(stock: Stock, searchString: string): boolean {
  const product = stock.storageUnit.productPack.product;
  return [product.sku, product.name]
    .some(v => v.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()));
}

// noinspection LocalVariableNamingConventionJS
const {
  selectedPrinter,
  selectedPrinterPaperLayout,
  loadPrinters,
  printNow,
  PrintBtn,
  EnqueueBtn,
} = useDocumentsPrinting();

const printingState = useDocumentsPrintingState();

const barcodeType = ref(printingState.value.lastContainerBarcodeType ?? PrintBarcodeTypeEnum.BARCODE);

watch(barcodeType, (): void => {
  printingState.value.lastContainerBarcodeType = barcodeType.value;
});

async function print() {
  await printNow('container-barcode', [{
    count: 1,
    params:{
      containerId: container.value!.id,
      barcodeType: barcodeType.value,
    }
  }], selectedPrinterPaperLayout.value!);
}

const changingContainerDialog = ref(false);

const { BlurredInput } = useOmniInput({ skip: changingContainerDialog });

async function handleBarcodeInput(value: string) {
  const { data } = await urql.query<{ container: Container | null }>(gql`
    query ContainerByBarcodeToOpenFromMobileCard($barcode: String!) {
      container: containerByBarcode(barcode: $barcode) { id }
    }
  `, { barcode: value });

  if (data!.container) {
    emit('goto-container', data!.container.id);
  }
}

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/printing.yaml"></i18n>

<i18n lang="yaml">
ru:
  Code: Код
  Go to Containers List: Перейти к списку контейнеров
  Change Cell: Изменить ячейку
  No products: Нет товара

en:
  Code: Code
  Go to Containers List: Go to Containers List
  Change Cell: Change Cell
  No products: No products

</i18n>
