<template>
  <SupplyDetailsMobile
    v-if="preferences.terminalMode"
    :id="id"
    :title="t('Customer Return')"
    @close="router.push({ name: ROUTES.CUSTOMER_RETURNS_LIST })"
  >
    <template #not-found-banner>
      <CustomerReturnNotFoundBanner />
    </template>
  </SupplyDetailsMobile>
  <SupplyDetailsDesktop
    v-else
    :id="id"
  >
    <template #title="{ supply }">
      {{ t('Customer Return') }}
      {{
        t('{id} dated {date}', {
          id: supply.externalId,
          date: formatDate(supply.createdAt)
        })
      }}
    </template>
    <template #fields="{ supply }: { supply: CustomerReturn }">
      <div class="col-sm-3">
        <QInput
          readonly
          :label="t('Customer')"
          :model-value="supply.counterparty?.name"
          stack-label
          :placeholder="t('Not specified')"
        />
      </div>
      <div class="col-sm-3">
        <QInput
          readonly
          :label="t('Order Number')"
          :model-value="supply.orderNumber || supply.customerOrder?.externalId"
          stack-label
          :placeholder="t('Not specified')"
        />
      </div>
      <div class="col-sm-3">
        <QInput
          readonly
          :label="t('Planned Arrival Date')"
          :model-value="formatDate(supply.plannedArrivalDate)"
          stack-label
          :placeholder="t('Not specified')"
        />
      </div>
    </template>
    <template #not-found-banner>
      <CustomerReturnNotFoundBanner />
    </template>
  </SupplyDetailsDesktop>
</template>

<script setup lang="ts">

import NotFoundBanner from '@/components/NotFoundBanner.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import useLocalPreferences from '@/composables/useLocalPreferences';
import type { CustomerReturn, Scalars } from '@/graphql/types';
import ROUTES from '@/router/routeNames';
import SupplyDetailsDesktop from '@/views/Arrival/Supply/SupplyDetailsDesktop.vue';
import SupplyDetailsMobile from '@/views/Arrival/Supply/SupplyDetailsMobile.vue';
import { type Component, h } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

defineProps<{
  id: Scalars['ID'];
}>();

const preferences = useLocalPreferences();

useBreadcrumbs(t('Supplies'));

const router = useRouter();

const CustomerReturnNotFoundBanner: Component = () => h(NotFoundBanner, {
  notFoundMessage: t('Customer Return not found'),
  backRoute:       { name: ROUTES.CUSTOMER_RETURNS_LIST },
  goBackMessage:   t('Go to Customer Returns List'),
});

</script>

<i18n lang="yaml">
ru:
  Customer Return not found: Возврат покупателя не найден
  Go to Customer Returns List: Перейти к списку возвратов
  Planned Arrival Date: Планируемая дата поступления

en:
  Customer Return not found: Customer Return not found
  Go to Customer Returns List: Go to Customer Returns List
  Planned Arrival Date: Planned Arrival Date
</i18n>
