<template>
  <div class="col justify-center">
    <ScrollCarousel
      v-if="store.carouselItems.length > 0"
      v-model="store.selectedItemIndex"
      :items="store.carouselItems"
      dont-reset-on-items-change
    >
      <template #item="{ item, index }">
        <TransferTaskCarouselSlide
          :item="item"
          :index="index"
          :display-amount="item.takenAmount"
          @delete="emit('delete-selected')"
          @product-click="editingProduct = $event"
          @update:slide="store.selectedItemIndex = $event"
        />
      </template>
      <template #indicators="{index, total}">
        <ScrollCarouselCounter
          :current="index + 1"
          :total="total"
          :suggested="store.currentItemIndex + 1"
          @to-current="store.resetCarouselSliderPosition()"
        />
      </template>
    </ScrollCarousel>
    <div class="q-pa-lg justify-center">
      <BaseScanField
        ref="scanField"
        :hint="t('Scan and take needed Product')"
        :search-fn="searchFn"
        :placeholder="t('Product')"
        :not-found-message="t('Wrong Product')"
        :rules="rules"
        :disabled="disabled"
        no-omni-input-scan
        @scan="emit('scan', $event)"
      />
    </div>
    <ProductEditDialog
      v-if="editingProduct"
      :id="editingProduct.id"
      @cancel="editingProduct = null"
    />
  </div>
</template>

<script setup lang="ts">

import type { Container, ProductPack, StorageUnit } from '@/graphql/types';
import { useI18n } from 'vue-i18n';
import type { FunctionValidationRule } from '@/types';
import type { Ref } from 'vue';
import { ref } from 'vue';
import ScrollCarousel from '@/components/Mobile/ScrollCarousel.vue';
import ScrollCarouselCounter from '@/components/Mobile/ScrollCarouselCounter.vue';
import useTransferProcessStore from '@/stores/transferProcess';
import TransferTaskCarouselSlide from '@/views/Mobile/Transfer/TransferTaskCarouselSlide.vue';
import ProductEditDialog from '@/views/Mobile/ProductEditDialog.vue';
import type { Product } from '@/graphql/types';
import BaseScanField from '@/components/Mobile/BaseScanField.vue';

const { t } = useI18n();

const store = useTransferProcessStore();
const editingProduct = ref<Product | null>(null);

defineProps<{
  productPack: ProductPack;
  availableAmount?: number;
  neededScannedAmount: number;
  rules?: FunctionValidationRule[];
  disabled?: boolean;
  scannedAmount?: number;
  searchFn: ((barcode: string | null) => object);
}>();

const emit = defineEmits<{
  (e: 'delete-selected'): void;
  (e: 'scan', storable: StorageUnit | Container): void;
}>();

const scanField: Ref<InstanceType<typeof BaseScanField>> = ref(null!);

defineExpose({
  scan: (value: string) => scanField.value?.scan(value),
});

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/selection.yaml"></i18n>

<style scoped lang="scss">

.width-transition {
  transition: width $animate-duration ease;
}

</style>
