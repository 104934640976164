<template>
  <QPage class="column">
    <div class="col">
      <QTabs v-model="listTab">
        <QTab name="pending">
          {{ t('Pending') }}
        </QTab>
        <QTab name="completed">
          {{ t('Completed') }}
        </QTab>
      </QTabs>

      <QTabPanels
        v-model="listTab"
        animated
      >
        <QTabPanel
          name="pending"
          class="q-pa-none"
        >
          <QToolbar class="justify-end">
            <QBtn
              flat
              round
              icon="mdi-refresh"
              :loading="inventoriesLoading"
              @click="loadInventories"
            />
          </QToolbar>
          <QList separator>
            <InventoryListItemSkeleton v-if="inventoriesLoading" />
            <QItem v-else-if="pendingInventories.length === 0">
              <QItemSection>
                <QItemLabel>
                  {{ t('No Inventories') }}
                </QItemLabel>
              </QItemSection>
            </QItem>
            <template v-else>
              <InventoryListItem
                v-for="inventory in pendingInventories"
                :key="inventory.id"
                :inventory="inventory"
                :to="{ name: ROUTES.INVENTORY_PROCESS, params: { inventoryId: inventory.id } }"
                clickable
              />
            </template>
          </QList>
        </QTabPanel>
        <QTabPanel
          name="completed"
          class="q-pa-none"
        >
          <QList>
            <QToolbar class="justify-end">
              <QBtn
                flat
                round
                icon="mdi-refresh"
                :loading="inventoriesLoading"
                @click="loadInventories"
              />
            </QToolbar>
            <InventoryListItemSkeleton v-if="inventoriesLoading" />
            <template v-else-if="completedInventories.length > 0">
              <template
                v-for="inv in completedInventories"
                :key="inv.id"
              >
                <InventoryListItem
                  :inventory="inv"
                  :to="{ name: ROUTES.INVENTORIES_EDIT, params: { id: inv.id } }"
                />
                <QSeparator />
              </template>
            </template>
            <QItem v-else>
              <QItemSection>
                <QItemLabel>
                  {{ t('No Inventories') }}
                </QItemLabel>
              </QItemSection>
            </QItem>
          </QList>
        </QTabPanel>
      </QTabPanels>
    </div>

    <ButtonsRow
      v-slot="{ buttonProps }"
      v2
    >
      <QBtn
        v-bind="buttonProps"
        icon="mdi-plus-circle"
        :to="{ name: ROUTES.INVENTORY_PROCESS }"
      >
        {{ t('Create') }}
      </QBtn>
    </ButtonsRow>
  </QPage>
</template>

<script setup lang="ts">

import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import type {
  Inventory,
  InventoryItem,
  QueryInventoriesByStatesArgs,
} from '@/graphql/types';
import { InventoryStateEnum } from '@/graphql/types';
import type { PendingInventory } from '@/types/inventory';
import useInventoryState from '@/views/Mobile/Inventory/useInventoryState';
import { gql, useQuery } from '@urql/vue';
import type { Component } from 'vue';
import { computed, h, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import InventoryListItem from './InventoryListItem.vue';
import { QItem, QItemSection, QSkeleton } from 'quasar';
import { uniqBy } from 'ramda';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const MAX_INVENTORIES = 10;

const listTab = ref('pending');

const storedState = useInventoryState();

const {
  data:         inventoriesResult,
  fetching:     inventoriesLoading,
  executeQuery: loadInventories,
} = useQuery<{ inventories: Inventory[] }, QueryInventoriesByStatesArgs>({
  query: gql`
    query GetInventoriesByStates($states: [InventoryStateEnum!]!, $limit: Int) {
      inventories: inventoriesByStates(states: $states, limit: $limit) {
        id
        createdAt
        state
        storage {
          __typename
          id
          name
          storageCell { id name }
        }
        isEntireStorage
        items {
          id
          storable  { id product { id } }
        }
        productPacks { id product { id } }
      }
    }
  `,
  variables: {
    states: [
      InventoryStateEnum.CREATED,
      InventoryStateEnum.IN_PROGRESS,
      InventoryStateEnum.COMPLETED,
      InventoryStateEnum.NEEDS_RECOUNT
    ],
    limit:  MAX_INVENTORIES,
  },
});

const inventoriesInProgress = computed(() =>
  inventoriesResult.value?.inventories
    .filter(i => i.state === InventoryStateEnum.CREATED
      || i.state === InventoryStateEnum.IN_PROGRESS) ?? []);

const storedInventories = computed(() =>
  storedState.inventories.value.map(state => ({
    ...(inventoriesInProgress.value.find(i => i.id === state.inventoryId) ?? {
      user:         null,
      createdAt:    null,
      state:        InventoryStateEnum.IN_PROGRESS,
      productPacks: [],
      movements:    [],
      locks:        [],
    }),
    id:              state.inventoryId,
    isEntireStorage: state.isEntireStorage,
    storage:         state.storage!,
    items:           state.actualStocks
      .filter(s => s.storable.__typename === 'ProductPack')
      .map(s => ({
        ...s,
        diff: 0,
      } as InventoryItem)),
  }))
);

const pendingInventories = computed((): PendingInventory[] => uniqBy(
  // В storedInventories могут содержаться инвентаризации,
  // которые также присутствуют в inventoriesInProgress.
  inv => inv.id,
  [
    // Инвентаризации из local storage выводим первыми
    ...storedInventories.value,
    ...inventoriesInProgress.value,
  ],
));

const completedInventories = computed<Inventory[]>(
  () => inventoriesResult.value?.inventories.filter(
    i => i.state === InventoryStateEnum.COMPLETED || i.state === InventoryStateEnum.NEEDS_RECOUNT,
  ) ?? [],
);

// noinspection LocalVariableNamingConventionJS
const InventoryListItemSkeleton: Component = () => h(QItem, () => [
  h(QItemSection, { side: true },() => [
    h(QSkeleton, { type: 'text', width: '40px' }),
    h(QSkeleton, { type: 'text', width: '80px' }),
    h(QSkeleton, { type: 'text', width: '60px' }),
  ]),
  h(QItemSection),
  h(QItemSection, { side: true },() => [
    h(QSkeleton, { type: 'text', width: '80px' }),
    h(QSkeleton, { type: 'text', width: '40px' }),
    h(QSkeleton, { type: 'QChip', width: '100px', height: '20px' }),
  ]),
]);

</script>

<i18n lang="yaml">
ru:
  No Inventories: Инвентаризации отсутствуют

en:
  No Inventories: No Inventories
</i18n>
