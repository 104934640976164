<template>
  <CardTitle>
    {{ title }}
  </CardTitle>
  <BaseTable
    :rows="data?.productShipments ?? []"
    :fields="tableFields"
    :loading="fetching"
    :storage-prefix="storagePrefix"
    without-search
  >
    <template #body-cell-shipment="column">
      <BodyCellLink
        :column="column"
        :to="shipmentCardRoute(column.value)"
        variant="link"
      >
        {{ column.value.externalId }}
      </BodyCellLink>
    </template>
  </BaseTable>
</template>

<script setup lang="ts">

import BaseTable from '@/components/BaseTable.vue';
import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import CardTitle from '@/components/CardTitle.vue';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import type {
  Product,
  QueryProductShipmentsArgs,
  ShipmentDataForProduct,
  CustomerOrder,
  SupplierReturn,
} from '@/graphql/types';
import itemAmount from '@/helpers/itemAmount';
import shipmentCardRoute from '@/helpers/shipmentCardRoute';
import { gql, useQuery } from '@urql/vue';
import type { QTableColumn } from 'quasar';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const props = defineProps<{
  product: Product;
  type: CustomerOrder['__typename'] | SupplierReturn['__typename'];
  title: string;
  storagePrefix: string;
}>();

const tableFields: QTableColumn<ShipmentDataForProduct>[] = [
  {
    label: props.type === 'SupplierReturn' ? t('Return') : t('Order'),
    name:  'shipment',
    field: 'shipment',
  },
  {
    label:  t('Created at'),
    name:   'createdAt',
    field:  row => row.shipment.createdAt,
    format: date => formatDate(date),
  },
  {
    label:  t('Planned Shipment Date'),
    name:   'plannedShipmentDate',
    field:  row => row.shipment.plannedShipmentDate,
    format: date => formatDate(date),
  },
  {
    label: props.type === 'SupplierReturn' ? t('Supplier') : t('Customer'),
    name:  'counterparty',
    field: row => row.shipment.counterparty?.name,
  },
  {
    label: t('Carrier Name'),
    name:  'carrier',
    field: row => row.shipment.carrier?.name ?? '',
  },
  {
    label:  t('State'),
    name:   'state',
    field:  row => row.shipment.state,
    format: state => t(`shipmentState.${state}`),
  },
  {
    label: t('Amount'),
    name:  'itemAmount',
    field: i => itemAmount({
      amount:      i.shipmentItem.amount * i.shipmentItem.productPack.quantityInMinMeasurementUnits,
      productPack: i.shipmentItem.productPack.product.mostBasicProductPack,
    }),
  },
  {
    label: t('Selected'),
    name:  'selectedAmount',
    field: i => itemAmount({
      amount:      (i.shipmentItem.selectedAmount ?? 0) - (i.shipmentItem.shippedAmount ?? 0),
      productPack: i.shipmentItem.productPack.product.mostBasicProductPack,
    }),
  },
  {
    label: t('Reserved'),
    name:  'reservedAmount',
    field: i => itemAmount({
      amount:      (i.shipmentItem.reservedAmount ?? 0) - (i.shipmentItem.amountInSelections ?? 0),
      productPack: i.shipmentItem.productPack.product.mostBasicProductPack,
    }),
  },
  {
    label: t('Shipped'),
    name:  'shippedAmount',
    field: i => itemAmount({
      amount:      i.shipmentItem.shippedAmount ?? 0,
      productPack: i.shipmentItem.productPack.product.mostBasicProductPack,
    }),
  },
  {
    label:         t('Not Reserved'),
    name:          'notReservedAmount',
    field:         i => itemAmount({
      amount:      i.shipmentItem.amount * i.shipmentItem.productPack.quantityInMinMeasurementUnits - (i.shipmentItem.reservedAmount ?? 0),
      productPack: i.shipmentItem.productPack.product.mostBasicProductPack,
    }),
    headerClasses: 'text-no-wrap',
  },
];

const { data, fetching } = useQuery<{ productShipments: ShipmentDataForProduct[] }, QueryProductShipmentsArgs>({
  query: gql`
    query GetProductOrdersData($productId: ID!, $type: String!) {
      productShipments(productId: $productId, type: $type) {
        shipment {
          __typename
          id
          externalId
          createdAt
          plannedShipmentDate
          counterparty { id name }
          carrier { id name }
          state
        }
        shipmentItem {
          id
          amount
          amountInSelections
          reservedAmount
          selectedAmount
          shippedAmount
          productPack {
            id
            quantityInMinMeasurementUnits
            product {
              id
              mostBasicProductPack {
                id
                smallerProductPack { id }
                minMeasurementUnit { id shortName }
                measurementUnit { id shortName }
                quantityInMinMeasurementUnits
              }
            }
          }
        }
      }
    }
  `,
  variables: computed(() => ({
    productId: props.product.id,
    type: props.type,
  }))
});

</script>
