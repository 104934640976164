<template>
  <MaximizedDialog
    model-value
    transition-show="slide-up"
    transition-hide="slide-down"
    :title="breadcrumbs.join(' / ')"
    @close="navigateBack({ name: ROUTES.TRANSFER_DASHBOARD })"
  >
    <BigLoading v-if="fetching" />
    <TransferDetails
      v-else-if="transfer"
      :transfer="transfer"
    />
  </MaximizedDialog>
</template>

<script setup lang="ts">

import type { QueryTransferArgs, Scalars, Transfer } from '@/graphql/types';
import BigLoading from '@/components/BigLoading.vue';
import ROUTES from '@/router/routeNames';
import { gql, useQuery } from '@urql/vue';
import { computed } from 'vue';
import MaximizedDialog from '@/components/MaximizedDialog.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import TransferDetails from '@/views/Mobile/Transfer/TransferDetails.vue';
import navigateBack from '@/helpers/navigateBack';

const { breadcrumbs } = useBreadcrumbs();

const props = defineProps<{
  id: Scalars['ID'];
}>();

const { data, fetching } = useQuery<{ transfer: Transfer }, QueryTransferArgs>({
  query: gql`
    query GetTransferForMobileDetails($id: ID!) {
      transfer(id: $id) {
        id
        createdAt
        movements {
          id
          amount
          storageFrom { id name }
          storageTo { id name }
          storable {
            __typename
            id
            productPack {
              id
              quantityInMinMeasurementUnits
              minMeasurementUnit { id shortName }
              product {
                id
                sku
                name
                photos {
                  id
                  url(size: SMALL)
                }
              }
            }
          }
        }
      }
    }
  `,
  variables: computed(() => ({ id: props.id }))
});

const transfer = computed(() => data.value?.transfer ?? null);

</script>
