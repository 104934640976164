<template>
  <MaximizedDialog
    model-value
    :title="t('Create')"
    :transition-duration="0"
    fixed-header
    @close="navigateBack({ name: ROUTES.SELECTION_DASHBOARD })"
  >
    <div class="full-height column no-wrap">
      <GraphQLReportList
        v-model:selected-items="selectedShipments"
        :graphql-query="shipmentsQuery"
        :available-filters="availableFilters"
        :fixed-filters="fixedFilters"
        with-search
        storage-prefix="selectionDashboard.ordersList.report"
        :no-data-label="t('No Orders')"
        class="col full-width"
      >
        <template #item="{ item, selected, toggle }">
          <ShipmentListItem
            v-if="item.hasStockForSelection || item.needsRestocking"
            :order="item"
            :active="selected"
            clickable
            disableable
            @click="onRowClick(item)"
          >
            <template #left>
              <QItemSection
                side
                class="q-pr-none"
              >
                <QCheckbox
                  :model-value="selected"
                  :disable="!item.hasStockForSelection"
                  @update:model-value="toggle"
                />
              </QItemSection>
            </template>
          </ShipmentListItem>
        </template>
      </GraphQLReportList>
    </div>
    <template #bottom>
      <QBanner
        v-if="selectedShipments.length > 0"
        class="bg-yellow"
        dense
      >
        <div class="row justify-around">
          <span>{{ t('{n} products', selectedProductsAmount) }}</span>
          <span>{{ formatWeight(selectedProductsWeight) }}</span>
          <span>{{ formatVolume(selectedProductsVolume) }}</span>
        </div>
      </QBanner>
      <ButtonsRow
        v-slot="{ buttonProps }"
        v2
      >
        <QBtn
          v-if="selectedShipments.length === 1"
          v-bind="buttonProps"
          icon="mdi-shopping"
          :to="{ name: ROUTES.SELECTION_ENQUEUE }"
        >
          {{ t('Selection by Shipment Order') }}
        </QBtn>
        <template v-if="selectedShipments.length > 1">
          <QBtn
            v-bind="buttonProps"
            icon="mdi-package-variant"
            :to="{ name: ROUTES.SELECTION_ENQUEUE }"
          >
            {{ t('Group Selection ({n})', {n: selectedShipments.length}) }}
          </QBtn>
          <QBtn
            v-bind="buttonProps"
            icon="mdi-group"
            :to="{ name: ROUTES.SELECTION_PROCESS_SCAN_CONTAINERS }"
          >
            {{ t('Cluster Selection ({n})', {n: selectedShipments.length}) }}
          </QBtn>
        </template>
        <QBtn
          v-if="selectedShipments.length > 0"
          v-bind="buttonProps"
          icon="mdi-close-circle-outline"
          @click="selectedShipments = []"
        >
          {{ t('Deselect') }}
        </QBtn>
      </ButtonsRow>
    </template>
  </MaximizedDialog>
</template>

<script setup lang="ts">

import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import GraphQLReportList from '@/components/Mobile/GraphQLReportList.vue';
import ShipmentListItem from '@/components/ShipmentListItem.vue';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type {
  OrdersGroupForSelection,
  QueryCarriersForSelectionArgs,
  QueryPlannedShipmentDatesForSelectionArgs,
  ReportFilterInput,
  CustomerOrder,
  Shipment,
} from '@/graphql/types';
import { filterToReportFilterInput } from '@/helpers/reports';
import useSelectionStore from '@/stores/selection';
import type { FilterOperatorList, ReportFilter } from '@/types/reports';
import { gql, useClientHandle } from '@urql/vue';
import { storeToRefs } from 'pinia';
import { QItem, QItemLabel, QItemSection } from 'quasar';
import { computed, h, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import MaximizedDialog from '@/components/MaximizedDialog.vue';
import { calculateVolumeForShipment, calculateWeight } from '@/helpers/shipments';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import navigateBack from '@/helpers/navigateBack';
import shipmentCardRoute from '@/helpers/shipmentCardRoute';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

useBreadcrumbs(t('Selection'));

const { formatDate, formatVolume, formatWeight } = useLocalizedFormatters();

const store = useSelectionStore();

const { selectedShipments } = storeToRefs(store);

onMounted(() => {
  selectedShipments.value = [];
});

const { client: urql } = useClientHandle();

const router = useRouter();

const shipmentsQuery = gql`
  query GetShipmentsForSelection(
    $first: Int!,
    $after: Cursor,
    $query: String,
    $filter: [ReportFilterInput!],
  ) {
    result: shipmentsListForSelection(
      first: $first,
      after: $after,
      query: $query,
      filter: $filter,
    ) {
      edges {
        cursor
        node {
          __typename
          id
          externalId
          state
          createdAt
          plannedShipmentDate
          counterparty { id name }
          carrier { id name }
          hasStockForSelection
          needsRestocking
          items {
            id
            amount(notSelectedOnly: true)
            productPack {
              id
              product { id }
              weight
              dimensions { length height width }
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const renderOrdersGroupOption: FilterOperatorList['renderOption'] = ({ qItemProps, option }) => h(
  QItem,
  { ...qItemProps },
  () => [
    h(QItemSection, () => h(QItemLabel, () => option.label)),
    h(QItemSection, { side: true }, () => `${option.ordersCount} ${t('ord.')}`),
  ],
);

const availableFilters: ReportFilter[] = [
  {
    label:                 t('Carrier Name'),
    hideLabelWhenSelected: true,
    field:                 'carrier',
    operators:             [
      {
        label:        t('reportFilterOperator.in'),
        value:        'in',
        type:         'multiList',
        values:       (searchString, filters) => urql.query<{
          carriers: OrdersGroupForSelection[];
        }, QueryCarriersForSelectionArgs>(
          gql`
            query GetCarriersForSelection($searchString: String, $filters: [ReportFilterInput!]!) {
              carriers: carriersForSelection(searchString: $searchString, filters: $filters) {
                title
                ordersCount
              }
            }
          `,
          { searchString, filters: filters.map(filterToReportFilterInput) },
        ).then(({ data }) => data!.carriers.map(({ title, ordersCount }) => ({
          value: title!,
          label: title!,
          ordersCount,
        }))),
        renderOption: renderOrdersGroupOption,
      },
    ],
  },
  {
    label:                 t('Planned Shipment Date'),
    hideLabelWhenSelected: true,
    field:                 'plannedShipmentDate',
    operators:             [
      {
        label:        '=',
        type:         'list',
        value:        'is same date',
        hideSearch:   true,
        values:       (searchString, filters) => urql.query<{
          dates: OrdersGroupForSelection[];
        }, QueryPlannedShipmentDatesForSelectionArgs>(
          gql`
            query GetPlannedShipmentDatesForSelection($filters: [ReportFilterInput!]!) {
              dates: plannedShipmentDatesForSelection(filters: $filters) {
                title
                ordersCount
              }
            }
          `,
          { filters: filters.map(filterToReportFilterInput) },
        ).then(({ data }) => data!.dates.map(({ title, ordersCount }) => ({
          value: title ?? null!,
          label: `${title ? formatDate(title, FORMATS.DATE) : t('Without Date')}`,
          ordersCount,
        })).filter(v => v.label.includes(searchString))),
        renderOption: renderOrdersGroupOption,
      },
    ],
  },
];

const fixedFilters: ReportFilterInput[] = [
  {
    field:    'canBeSelected',
    operator: '=',
    value:    JSON.stringify(true),
  },
  {
    field:    'type',
    operator: '=',
    value:    JSON.stringify('CustomerOrder' as CustomerOrder['__typename']),
  },
];

function onRowClick(row: Shipment): void {
  router.push(shipmentCardRoute(row));
}

const selectedProductsAmount = computed(() => selectedShipments.value
  .reduce((amount, order) => amount + order.items.filter(item => item.amount !== 0).length, 0));

const selectedProductsWeight = computed(() => selectedShipments.value
  .reduce((totalWeight, order) => totalWeight + calculateWeight(order), 0));

const selectedProductsVolume = computed(() => selectedShipments.value
  .reduce((totalWeight, order) => totalWeight + calculateVolumeForShipment(order), 0));

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/counts.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/selection.yaml"></i18n>

<i18n lang="yaml">
ru:
  No Orders: Заказы отсутствуют
  Group Selection ({n}): Групповой отбор ({n})
  Cluster Selection ({n}): Кластерный отбор ({n})
  Deselect: Снять выбор
  ord.: зак.
  Without Date: Без даты

en:
  No Orders: No Orders
  "Group Selection ({n})": Group Selection ({n})
  "Cluster Selection ({n})": Cluster Selection ({n})
  Deselect: Deselect
  ord.: ord.
  Without Date: Without Date

</i18n>
