<template>
  <QPage padding>
    <QCard>
      <BaseAlert
        v-if="getPrimaryError()"
        type="error"
      >
        {{ getPrimaryError() }}
      </BaseAlert>
      <QCardSection
        v-if="props.loading"
        class="text-center"
      >
        <BigLoading />
      </QCardSection>
      <template v-else-if="selection">
        <CardTitle>
          {{ t('Selection') }}
          {{
            t('{id} dated {date}', {
              id: selection.id,
              date: formatDate(selection.createdAt, FORMATS.DATETIME),
            })
          }}
          <OperationState
            v-if="selection"
            :operation="selection"
          />
          <QChip
            v-else
            :color="badgeColors.forSelectionState(selection.state)"
            text-color="black"
          >
            {{ t(`selectionState.${selection.state}`) }}
          </QChip>
        </CardTitle>

        <QCardSection class="row">
          <div
            v-if="selection"
            class="col-sm-4 col-md-3"
          >
            <NonEditableField :label="t('Type')">
              {{ t('selectionKind.' + selection.kind) }}
            </NonEditableField>
          </div>
          <div class="col-sm-4 col-md-3">
            <NonEditableField :label="t('User')">
              {{ userFullName(selection.user) }}
            </NonEditableField>
          </div>
          <div class="col-sm-4 col-md-3">
            <NonEditableField :label="t('Carrier Name')">
              {{ carriers.map(c => c.name).join(', ') }}
            </NonEditableField>
          </div>
        </QCardSection>

        <BaseTable
          :rows="selection.items"
          :fields="movementsTableHeaders"
          show-line-numbers
          no-toolbar
        >
          <template #body-cell-productSku="column">
            <BodyCellLink
              :column="column"
              :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: column.row.productPack.product.id } }"
              variant="link"
            />
          </template>
        </BaseTable>

        <QSeparator />

        <QCardActions>
          <QBtn
            exact
            icon="mdi-arrow-left"
            @click="emit('back')"
          >
            {{ t('Back') }}
          </QBtn>
          <ConfirmsAction
            v-if="canDelete"
            should-prompt
            :confirm-text="t('Yes')"
            :cancel-text="t('No')"
            @confirmed="deleteSelection()"
          >
            <template #activator="{ prompt }">
              <QBtn
                exact
                color="red"
                icon="mdi-delete"
                @click="prompt()"
              >
                {{ t('Delete') }}
              </QBtn>
            </template>
            <template #title>
              {{ t('Delete Selection?') }}
            </template>
          </ConfirmsAction>
          <QSpace />

          <SelectionChangeContainerDialog
            v-if="canChangeContainer"
            v-slot="{ onOpen }"
            :selection="selection"
          >
            <QBtn
              :label="t('Change Container')"
              icon="mdi-auto-fix"
              @click="onOpen"
            />
          </SelectionChangeContainerDialog>
        </QCardActions>
      </template>
      <BaseAlert
        v-else
        type="info"
        icon="mdi-run-fast"
      >
        <p>
          {{ t('Selection not found') }}
        </p>
        <QBtn
          :loading="progress.deleting"
          exact
          color="primary"
          icon="mdi-arrow-left"
          @click="navigateBack({ name: ROUTES.OPERATIONS_LIST })"
        >
          {{ t('Go to Operations List') }}
        </QBtn>
      </BaseAlert>
    </QCard>
  </QPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import BaseTable from '@/components/BaseTable.vue';
import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import BigLoading from '@/components/BigLoading.vue';
import CardTitle from '@/components/CardTitle.vue';
import NonEditableField from '@/components/NonEditableField.vue';
import OperationState from '@/components/OperationState.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type { Carrier, Movement, Selection, SelectionItem, Storage } from '@/graphql/types';
import {
  SelectionKindEnum,
  SelectionStateEnum,
} from '@/graphql/types';
import userFullName from '@/helpers/userFullName';
import type { TableColumn } from '@/types/reports';
import { uniqBy } from 'ramda';
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import * as badgeColors from '@/helpers/badgeColors';
import SelectionChangeContainerDialog from '@/views/Shipping/SelectionChangeContainerDialog.vue';
import navigateBack from '@/helpers/navigateBack';
import ConfirmsAction from '@/components/ConfirmsAction.vue';
import useSelectionStore from '@/stores/selection';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const { getPrimaryError } = useErrorHandling();

const store = useSelectionStore();

const emit = defineEmits<{
  (e: 'back'): void;
}>();

const props = defineProps<{
  selection: Selection;
  loading: boolean;
}>();

const progress = reactive({
  deleting:   false,
});

const selection = computed(() => props.selection);

const carriers = computed(() => uniqBy(
  c => c.id,
  selection.value.shipments
    .map(s => s.carrier)
    .filter(Boolean) as Carrier[],
));

const canDelete = computed(() => selection.value.movements.length === 0);

async function deleteSelection(): Promise<void> {
  progress.deleting = true;
  await store.deleteSelection(selection.value!);
  progress.deleting = false;

  navigateBack({ name: ROUTES.OPERATIONS_LIST });
}

function movementsForItem(item: SelectionItem): Movement[] {
  const container = selection.value.selectionOrders.find(so => so.shipment.id === item.shipment.id)?.container;

  if (!container) {
    return [];
  }

  return selection.value.movements
    .filter(
      m => m.storable.productPack.product.mostBasicProductPack.id === item.productPack.id
              && m.storageTo!.id === container.id
    ) ?? [];
}

function formatStorages(storages: Storage[]) {
  return storages.map(s => s.name).join(', ');
}

const movementsTableHeaders: TableColumn<SelectionItem>[] = [
  {
    label:  t('Cell'),
    name:   'cellFrom',
    field:  item => uniqBy(p => p!.id, movementsForItem(item)
      .map(m => m.storageFrom)
      .filter(Boolean)),
    format: formatStorages,
    align:  'left',
  },
  {
    label: t('SKU'),
    name:  'productSku',
    field: i => i.productPack.product.sku,
    align: 'left',
  },
  {
    label: t('Product Name'),
    name:  'productName',
    field: i => i.productPack.product.name,
    align: 'left',
  },
  {
    label:  t('Container'),
    name:   'containerTo',
    field:  item => uniqBy(Boolean, movementsForItem(item)
      .map(m => m.storageTo)
      .filter(Boolean)),
    format: formatStorages,
    align:  'left',
  },
  {
    label: t('Amount in queued Selection'),
    name:  'amountInSelection',
    field: i => i.productPack.quantityInMinMeasurementUnits * i.amount,
  },
  {
    label:  t('Orders'),
    name:   'shipments',
    field:  i => `${i.shipment.externalId}`,
    align:  'left',
  },
];

const canChangeContainer = computed(
  () => selection.value.state === SelectionStateEnum.SELECTED && selection.value.kind !== SelectionKindEnum.CLUSTER,
);

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/selection.yaml"></i18n>

<i18n lang="yaml">
ru:
  Selection not found: Отбор не найден
  Go to Operations List: К списку операций
  Amount in queued Selection: Количество по заданию
  Change Container: Изменить контейнер

en:
  Selection not found: Selection not found
  Go to Operations List: Go to Operations List
  Amount in queued Selection: Amount in queued Selection
  Change Container: Change Container
</i18n>
