<template>
  <QPage padding>
    <BigLoading v-if="fetchingSupply" />
    <PrimaryErrorBanner v-else-if="primaryError" />

    <QCard v-else-if="supply">
      <CardTitle>
        <slot
          name="title"
          :supply="supply"
        />
        <QChip
          :color="badgeColors.forSupplyState(supply)"
          text-color="black"
        >
          {{ t('supplyState.' + supply.state) }}
        </QChip>
      </CardTitle>

      <QCardSection>
        <div class="row q-col-gutter-md">
          <slot
            name="fields"
            :supply="supply"
          />
          <div class="col-sm-3">
            <LoadingLinksField
              :query="arrivalItemsQueryArgs"
              :label="t('Arrivals')"
            >
              <template #item="{item}">
                <RouterLink :to="{ name: ROUTES.ARRIVALS_EDIT, params: { id: item.id } }">
                  {{ item.id }}
                </RouterLink>
                <OperationState
                  :operation="item"
                  dense
                  class="q-my-none q-mr-none"
                />
              </template>
            </LoadingLinksField>
          </div>
        </div>
      </QCardSection>

      <GraphQLQueryTable
        :fields="itemsTableHeaders"
        :graphql-query="query"
        :available-filters="availableFilters"
        :fixed-filters="fixedFilters"
        :row-is-deleted="item => item.productPack.deletedAt !== null"
        storage-prefix="supplyEdit.itemsList"
        show-line-numbers
      >
        <template #batch-actions="{ rows, clear }">
          <SimpleTableBatchActions :rows-count="rows.length">
            <ProductsPrintDialog
              :products="rows.map(item => item.productPack.product)"
              :supply="supply"
              @done="clear"
            />
          </SimpleTableBatchActions>
        </template>
        <template #body-cell-sku="column">
          <BodyCellLink
            :column="column"
            :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: column.row.productPack.product.id } }"
            variant="link"
          />
        </template>
        <template #body-cell-productName="column">
          <QTd :props="column">
            <div
              v-clamp="3"
              style="white-space: normal"
            >
              {{ column.value }}
            </div>
          </QTd>
        </template>
        <template #body-cell-productPackMeasurementUnit="column">
          <QTd
            :props="column"
            :title="column.value.name"
          >
            {{ column.value.shortName }}
          </QTd>
        </template>
      </GraphQLQueryTable>

      <QSeparator />

      <QCardActions>
        <QBtn
          exact
          icon="mdi-arrow-left"
          @click="navigateBack({ name: ROUTES.SUPPLIES_LIST })"
        >
          {{ t('Back') }}
        </QBtn>

        <QSpace />

        <SupplyCompleteButton
          :supply="supply"
          @update:state="supply.state = $event"
        />
      </QCardActions>
    </QCard>
    <slot
      v-else
      name="not-found-banner"
    />
  </QPage>
</template>

<script setup lang="ts">

import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import BigLoading from '@/components/BigLoading.vue';
import CardTitle from '@/components/CardTitle.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import vClamp from '@/directives/clamp';
import type {
  ProductPack,
  QuerySupplyArgs,
  ReportFilterInput,
  Scalars,
  Supply,
  SupplyItem,
} from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import itemAmount from '@/helpers/itemAmount';
import navigateBack from '@/helpers/navigateBack';
import { createBoolean, createList } from '@/helpers/reports';
import ROUTES from '@/router/routeNames';
import type { ReportFilter, TableColumn } from '@/types/reports';
import SupplyCompleteButton from '@/views/Arrival/Supply/SupplyCompleteButton.vue';
import ProductsPrintDialog from '@/views/Products/ProductsPrintDialog.vue';
import SimpleTableBatchActions from '@/views/SimpleTableBatchActions.vue';
import { gql, useQuery } from '@urql/vue';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import OperationState from '@/components/OperationState.vue';
import LoadingLinksField from '@/components/LoadingLinksField.vue';

const { t } = useI18n();

const { fillErrorsFromGraphQLError, primaryError, PrimaryErrorBanner } = useErrorHandling();

const props = defineProps<{
  id: Scalars['ID'];
}>();

const arrivalItemsQueryArgs = computed(() => ({
  query:     gql`
    query GetArrivalsForSupplyDetails($supplyId: ID!) { items: arrivalsBySupply(supplyId: $supplyId) {
      __typename
      id
      state
      createdAt
    } }
  `,
  variables: { supplyId: props.id },
}));

const {
  data: supplyData,
  fetching: fetchingSupply,
  error: supplyError,
} = useQuery<{ supply: Supply }, QuerySupplyArgs>({
  query:     gql`
    query GetSupply($id: ID!) { supply(id: $id) {
      __typename
      id
      createdAt
      plannedArrivalDate
      counterparty { id name }
      ... on CustomerReturn { orderNumber customerOrder { id externalId } }
      ... on SupplierDelivery {
        supplierReturns { id externalId createdAt state}
      }
      state
      externalId
      items {
        id
        productPack { id }
        amount
      }
    } }
  `,
  variables: computed(() => ({
    id: props.id,
  })),
});

watch(supplyError, fillErrorsFromGraphQLError);
watch(supplyData, supplyData => {
  if (supplyData?.supply) {
    supply.value = supplyData.supply;
  }
});
const supply = ref<Supply>();

const availableFilters: ReportFilter[] = [
  {
    field:     'arrivalStatus',
    label:     t('Arrived'),
    operators: [
      ...(['=', '!='] as const).map(op => createList(op, () => Promise.resolve([
        {
          label: t('Shortage'),
          value: 'shortage',
        },
        {
          label: t('Match'),
          value: 'match',
        },
        {
          label: t('Excess'),
          value: 'excess',
        },
      ]), t(`reportFilterOperator.${op}`))),
    ],
  },
  {
    field:     'hasDefect',
    label:     t('Defect'),
    operators: [
      createBoolean('='),
    ],
  },
];

const fixedFilters = computed<ReportFilterInput[]>(() => ([{
  field:    'supplyId',
  operator: '=',
  value:    JSON.stringify(props.id),
}]));

const query = gql`
  query GetSupplyItems($page: Int, $perPage: Int!, $query: String, $filter: [ReportFilterInput!], $sort: [ReportSortInput!]!) {
    result: supplyItemsReport(page: $page, perPage: $perPage, query: $query, filter: $filter, sort: $sort) {
      data {
        id
        amount
        arrivedAmount
        defectAmount
        productPack {
          id
          product {
            id
            sku
            name
            photos { id url(size: SMALL) }
            productPacks {
              id
              lastScannedBarcode { barcode group }
              barcodes { barcode group }
              measurementUnit { id }
            }
            mostBasicProductPack {
              id
              lastScannedBarcode { barcode group }
              barcodes { barcode group }
              measurementUnit { id name shortName }
            }
          }
          measurementUnit { id name shortName }
          quantity
          quantityInMinMeasurementUnits
          minMeasurementUnit { id shortName }
          smallerProductPack { id quantity }
          deletedAt
        }
      }
      total
    }
  }
`;

const itemsTableHeaders: TableColumn<SupplyItem>[] = [
  {
    label: t('SKU'),
    name:  'sku',
    field: 'productPack',
    format: (pack: ProductPack) => pack.product.sku,
    align: 'left',
  },
  {
    label: t('Name'),
    name:  'productName',
    field: 'productPack',
    format: (pack: ProductPack) => pack.product.name,
    align: 'left',
  },
  {
    label:         t('Amount'),
    name:          'itemAmount',
    field:         i => itemAmount({
      productPack: i.productPack.product.mostBasicProductPack,
      amount:      i.amount * i.productPack.quantityInMinMeasurementUnits,
    }),
    requestFields: ['productPack', 'amount'],
  },
  {
    label:         t('Arrived'),
    name:          'arrivedAmount',
    field:         i => itemAmount({
      productPack: i.productPack.product.mostBasicProductPack,
      amount:      (i.arrivedAmount ?? 0) - (i.defectAmount ?? 0),
    }),
    requestFields: ['productPack', 'arrivedAmount', 'defectAmount'],
  },
  {
    label:         t('Arrived Defect'),
    name:          'defectAmount',
    field:         i => itemAmount({
      productPack: i.productPack.product.mostBasicProductPack,
      amount:      (i.defectAmount ?? 0),
    }),
    requestFields: ['productPack', 'defectAmount'],
  },
  {
    label:         t('Not Arrived'),
    name:          'notArrivedAmount',
    field:         i => itemAmount({
      productPack: i.productPack.product.mostBasicProductPack,
      amount:      i.amount * i.productPack.quantityInMinMeasurementUnits - (i.arrivedAmount ?? 0),
    }),
    requestFields: ['productPack', 'amount', 'arrivedAmount'],
  },
];

</script>

<i18n lang="yaml">
ru:
  Arrived: Принято
  Arrived Defect: Принято брак
  Not Arrived: Не принято
  Shortage: Недостача
  Match: Совпало
  Excess: Излишки

en:
  Arrived: Arrived
  Arrived Defect: Arrived Defect
  Not Arrived: Not Arrived
  Shortage: Shortage
  Match: Match
  Excess: Excess
</i18n>
