<template>
  <FullHeightPage>
    <GraphQLQueryTable
      ref="report"
      :graphql-query="query"
      :fields="tableFields"
      :available-filters="availableFilters"
      storage-prefix="transfersList.report"
      keep-page-in-url
      sticky-header
      class="col"
    >
      <template #body-cell-id="column">
        <BodyCellLink
          :column="column"
          :to="{ name: ROUTES.TRANSFERS_EDIT, params: { id: column.value } }"
        />
      </template>

      <template #body-cell-state="column">
        <QTd :props="column">
          <QChip
            :color="badgeColors.forOperationState(column.row)"
            text-color="black"
          >
            {{ column.value }}
          </QChip>
        </QTd>
      </template>

      <template #body-cell-task="column">
        <BodyCellLink
          v-if="column.value"
          :column="column"
          :to="{ name: ROUTES.TRANSFER_TASKS_EDIT, params: { id: column.value.id } }"
          variant="link"
        >
          {{ column.value.id }}
        </BodyCellLink>
      </template>

      <template #batch-actions="{rows, refresh}">
        <SimpleTableBatchActions :rows-count="rows.length">
          <DeletionWithValidation
            :items="rows"
            :delete="deleteTransfer"
            :validate="validateTransfer"
            @deleted="refresh()"
          >
            <template #validation-error-isNotInCreatedState="{ items }">
              <BaseAlert type="error">
                {{
                  t(
                    'Transfers {ids} have a state different from "Created"',
                    {ids: items.map((r => r.id)).join(', ')},
                    items.length,
                  )
                }}
              </BaseAlert>
            </template>
            <template #confirmation>
              <QCardSection class="text-body1">
                {{ t('Delete {n} transfers?', { id: rows[0].id }, rows.length) }}
              </QCardSection>
            </template>
          </DeletionWithValidation>
        </SimpleTableBatchActions>
      </template>
    </GraphQLQueryTable>
  </FullHeightPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import FullHeightPage from '@/components/FullHeightPage.vue';
import type GraphQLQueryTableType from '@/components/GraphQLQueryTable.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import {
  type MutationDeleteOperationArgs,
  type Transfer,
  TransferStateEnum,
} from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import * as reports from '@/helpers/reports';
import { createDatesRangeOperator, dashIfNil } from '@/helpers/reports';
import userFullName from '@/helpers/userFullName';
import ROUTES from '@/router/routeNames';
import type { FunctionValidationRule, TypenameAware } from '@/types';
import type { ReportFilter, TableColumn } from '@/types/reports';
import DeletionWithValidation from '@/views/DeletionWithValidation.vue';
import SimpleTableBatchActions from '@/views/SimpleTableBatchActions.vue';
import { gql, useClientHandle } from '@urql/vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

useBreadcrumbs(t('Transfers'));

const { client: urql } = useClientHandle();

const { formatDate } = useLocalizedFormatters();

const report = ref<InstanceType<typeof GraphQLQueryTableType>>(null);

const tableFields: TableColumn<TypenameAware<Transfer>>[] = [
  {
    label:     t('ID'),
    name:      'id',
    field:     'id',
    mandatory: true,
    align:     'left',
    sortable:  true,
  },
  {
    label:    t('Created at'),
    name:     'createdAt',
    field:    'createdAt',
    format:   d => formatDate(d, FORMATS.DATETIME),
    align:    'left',
    sortable: true,
  },
  {
    label: t('User'),
    name:  'user',
    field:  'user',
    format: userFullName,
    align: 'left',
  },
  {
    label:  t('State'),
    name:   'state',
    field:  'state',
    align:  'left',
    format: state => t(`transferState.${state}`),
  },
  {
    label: t('Task'),
    name:  'task',
    field: 'task',
    align: 'left',
  },
  {
    label:  t('Packs'),
    name:   'packsAmount',
    field:  'packsAmount',
    format: dashIfNil,
  },
  {
    label:  t('Volume (cm³)'),
    name:   'volume',
    field:  'volume',
    format: dashIfNil,
  },
];

const availableFilters: ReportFilter[] = [
  {
    field:     'createdAt',
    label:     t('Created at'),
    operators: [
      createDatesRangeOperator(),
    ],
  },
  {
    field: 'byTask',
    label: t('By Task'),
    operators: [
      reports.createBoolean('is not null', '='),
    ],
  },
  {
    field: 'task',
    label: t('Task'),
    operators: [
      reports.createPrimitive('=', 'string'),
    ],
  },
  {
    field:     'state',
    label:     t('State'),
    operators: (['=', '!=', 'in', 'not in'] as const).map(op =>
      reports.createList(op, () => Promise.resolve([
        TransferStateEnum.CREATED,
        TransferStateEnum.IN_PROGRESS,
        TransferStateEnum.COMPLETED,
      ].map(v => ({
        value: v,
        label: t(`transferState.${v}`),
      }))), t(`reportFilterOperator.${op}`)),
    ),
  },
];

const query = gql`
  query GetOperations($page: Int, $perPage: Int!, $query: String, $filter: [ReportFilterInput!], $sort: [ReportSortInput!]!) {
    result: transfersReport(page: $page, perPage: $perPage, query: $query, filter: $filter, sort: $sort) {
      data {
        id
        createdAt
        user { firstname lastname }
        state
        packsAmount
        volume
        task { id }
      }
      total
    }
  }
`;

function validateTransfer(transfer: Transfer): ReturnType<FunctionValidationRule> {
  return transfer.state === TransferStateEnum.CREATED ? true : 'isNotInCreatedState';
}

async function deleteTransfer(transfer: Transfer): Promise<void> {
  await urql.mutation<unknown, MutationDeleteOperationArgs>(
    gql`mutation DeleteTransfer($transferId: ID!) { deleteTransfer(transferId: $transferId) }`,
    { transferId: transfer.id },
  );
}

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/transfer.yaml"></i18n>

<i18n lang="yaml">
ru:
  Packs: Упаковки
  Volume (cm³): Объем (см³)
  Transfers {ids} have a state different from "Created":
    Перемещения {ids} имеют статус, отличный от "Создан"
  Delete {n} transfers?: >
    Удалить {n} перемещений?
    | Удалить {n} перемещение?
    | Удалить {n} перемещения?
    | Удалить {n} перемещений?

en:
  Packs: Packs
  Volume (cm³): Volume (cm³)
  Transfers {ids} have a state different from "Created":
    Transfers {ids} have a state different from "Created"
  Delete {n} transfers?: >
    Delete {n} transfer?
    | Delete {n} transfers?
</i18n>
