<template>
  <QPage padding>
    <QCard>
      <BaseAlert
        v-if="getPrimaryError()"
        type="error"
      >
        {{ getPrimaryError() }}
      </BaseAlert>
      <QCardSection
        v-if="fetching"
        class="text-center"
      >
        <QCircularProgress
          indeterminate
          size="150px"
          color="primary"
          font-size="0.1em"
          show-value
        >
          {{ t('Loading') }}
        </QCircularProgress>
      </QCardSection>
      <template v-else-if="accountingModel">
        <CardTitle>
          {{ t('Accounting Model {name}', { name: accountingModel.name }) }}
        </CardTitle>

        <QSeparator />

        <QCardSection>
          <div class="row">
            <QInput
              v-model="accountingModel.name"
              class="col-sm-4"
              :label="t('Name')"
              :rules="[notEmptyRule]"
              autofocus
              v-bind="qErrorsFor('accountingModel.name')"
            />
          </div>
          <div class="row">
            <QSelect
              v-model="accountingModel.askForWeightAndDimensions"
              class="col-sm-4"
              :options="weightAndDimensionsNotifyOptionDisplayFormat"
              :option-label="weightAndDimensionsNotifyOptionFormatLabel"
              :label="t('Notify to fill Weight and Dimensions')"
              bottom-slots
            />
          </div>
          <div class="row q-col-gutter-sm">
            <QSelect
              v-model="accountingMethod"
              class="col-sm-4"
              :options="accountingMethods"
              :label="t('Accounting method')"
              :option-label="accountingMethodLabel"
              emit-value
            />
            <QSelect
              v-if="accountingModel.byBatch"
              v-model="accountingModel.batchDisplayFormat"
              class="col-sm-4"
              :options="batchDisplayFormats"
              :option-label="batchDisplayFormatLabel"
              :label="t('Batch display format')"
              :hint="t('Example: {value}', { value: batchDisplayFormatExample })"
            />
          </div>

          <div class="row">
            <div class="col-sm-4">
              <QCheckbox
                v-model="accountingModel.isDefault"
                :disable="isDefaultCheckBoxDisabled"
                :label="t('Choose by default')"
              />
            </div>
            <div class="col-sm-4 column items-start">
              <QCheckbox
                v-if="accountingModel.byBatch"
                v-model="accountingModel.batchNumberRequired"
                :label="t('Enter Batch number')"
              />
              <BaseCheckbox
                v-if="accountingModel.byBatch"
                v-model="accountingModel.batchProductionDateRequired"
                :disable="accountingModel.byShelfLife"
                :label="t('Enter Batch production date')"
                v-bind="qErrorsFor('accountingModel.batchProductionDateRequired')"
              />
              <BaseCheckbox
                v-if="accountingModel.byShelfLife"
                v-model="accountingModel.batchExpirationDateRequired"
                disable
                :label="t('Enter Batch expiration date')"
                v-bind="qErrorsFor('accountingModel.batchExpirationDateRequired')"
              />
            </div>
          </div>
        </QCardSection>

        <QSeparator />

        <QCardActions>
          <QBtn
            exact
            :to="{ name: ROUTES.WAREHOUSE_SETTINGS }"
            icon="mdi-arrow-left"
          >
            {{ t('Back') }}
          </QBtn>

          <QSpace />

          <QBtn
            color="success"
            :loading="hasProgress('applying')"
            icon="mdi-content-save"
            @click="save()"
          >
            {{ t('Apply') }}
          </QBtn>
          <QBtn
            color="primary"
            :loading="hasProgress('saving')"
            icon="mdi-content-save"
            @click="saveAndGoToList()"
          >
            {{ t('Save') }}
          </QBtn>
        </QCardActions>
      </template>
      <BaseAlert
        v-else
        type="info"
        icon="mdi-run-fast"
      >
        <p>
          {{ t('Accounting Model is not found') }}
        </p>
        <QBtn
          exact
          color="primary"
          icon="mdi-arrow-left"
          :to="{ name: ROUTES.WAREHOUSE_SETTINGS }"
        >
          {{ t('Go to Settings') }}
        </QBtn>
      </BaseAlert>
    </QCard>
  </QPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useErrorHandling from '@/composables/useErrorHandling';
import useProgressHandling from '@/composables/useProgressHandling';
import useValidationRules from '@/composables/useValidationRules';
import type { AccountingModel, MutationSaveAccountingModelArgs } from '@/graphql/types';
import {
  AskForWeightAndDimensionsOptionEnum,
  BatchDisplayFormatEnum,
} from '@/graphql/types';
import { gql, useClientHandle, useQuery } from '@urql/vue';
import { useI18n } from 'vue-i18n';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

type AccountingMethod =
  | 'noBatches'
  | 'byBatch'
  | 'byBatchAndShelfLife';

const {
  fillErrorsFromGraphQLError,
  clearErrors,
  getPrimaryError,
  qErrorsFor,
} = useErrorHandling();

const { progressStarted, hasProgress } = useProgressHandling<'applying' | 'saving'>();

const { notEmptyRule } = useValidationRules();

const { client: urql } = useClientHandle();

const router = useRouter();

const props = defineProps<{
  id?: string;
}>();

useBreadcrumbs([
  t('Accounting Models'),
  props.id ? t('Edit') : t('New'),
]);

const accountingModelFragment = gql`
  fragment AccountingModelForCard on AccountingModel {
    id
    name
    byBatch
    byShelfLife
    batchNumberRequired
    batchProductionDateRequired
    batchExpirationDateRequired
    batchDisplayFormat
    isDefault
    askForWeightAndDimensions
  }
`;

const { data, error, fetching } = useQuery<{ accountingModel: AccountingModel }>({
  query: gql`
    query GetAccountingModel($id: ID!) {
      accountingModel(id: $id) {
        ...AccountingModelForCard
      }
    }
    ${accountingModelFragment}
  `,
  variables: computed(() => ({
    id: props.id,
  })),
  pause: computed(() => !props.id),
});
watch(error, fillErrorsFromGraphQLError);
watch(data, data => {
  accountingModel.value = data!.accountingModel;

  isDefaultCheckBoxDisabled.value = accountingModel.value.isDefault!;
});
const accountingModel = ref<Partial<AccountingModel>>({
  name:                        '',
  byBatch:                     false,
  byShelfLife:                 false,
  batchNumberRequired:         false,
  batchProductionDateRequired: false,
  batchExpirationDateRequired: false,
  batchDisplayFormat:          null,
  isDefault:                   false,
  askForWeightAndDimensions:   AskForWeightAndDimensionsOptionEnum.NEVER,
});

const isDefaultCheckBoxDisabled = ref(false);

async function doSave(): Promise<boolean> {
  clearErrors();

  const { error, data } = await urql.mutation<{
    saveAccountingModel: AccountingModel;
  }, MutationSaveAccountingModelArgs>(
    gql`
      mutation SaveAccountingModelMutation($accountingModel: AccountingModelInput!) {
        saveAccountingModel(accountingModel: $accountingModel) {
          ...AccountingModelForCard
        }
      }
      ${accountingModelFragment}
    `,
    {
      accountingModel: {
        id:                          accountingModel.value.id,
        name:                        accountingModel.value.name!,
        byBatch:                     accountingModel.value.byBatch!,
        byShelfLife:                 accountingModel.value.byShelfLife!,
        batchNumberRequired:         accountingModel.value.batchNumberRequired!,
        batchProductionDateRequired: accountingModel.value.batchProductionDateRequired!,
        batchExpirationDateRequired: accountingModel.value.batchExpirationDateRequired!,
        batchDisplayFormat:          accountingModel.value.batchDisplayFormat,
        isDefault:                   accountingModel.value.isDefault!,
        askForWeightAndDimensions:   accountingModel.value.askForWeightAndDimensions
      },
    },
  );

  if (error) {
    fillErrorsFromGraphQLError(error);
    return false;
  }

  const newId = data!.saveAccountingModel.id;
  if (newId && props.id !== newId) {
    await router.push({
      name:   ROUTES.ACCOUNTING_MODELS_EDIT,
      params: { id: newId },
    });
  }

  return true;
}

async function save(): Promise<void> {
  const done = progressStarted('applying');

  await doSave();

  done();
}

async function saveAndGoToList(): Promise<void> {
  const done = progressStarted('saving');

  if (await doSave()) {
    // noinspection ES6MissingAwait
    router.push({ name: ROUTES.WAREHOUSE_SETTINGS });
  }

  done();
}

const accountingMethods: AccountingMethod[] = [
  'noBatches',
  'byBatch',
  'byBatchAndShelfLife',
];

const accountingMethodLabel = (m: AccountingMethod) => ({
  noBatches: t('No batches'),
  byBatch: t('By batch'),
  byBatchAndShelfLife: t('By batch and shelf life'),
}[m]);

const accountingMethod = computed<AccountingMethod>({
  get() {
    if (accountingModel.value.byBatch) {
      if (accountingModel.value.byShelfLife) {
        return 'byBatchAndShelfLife';
      }
      return 'byBatch';
    }
    return 'noBatches';
  },

  set(value) {
    switch (value) {
      case 'byBatch':
        accountingModel.value.byBatch = true;
        accountingModel.value.byShelfLife = false;
        break;
      case 'byBatchAndShelfLife':
        accountingModel.value.byBatch = true;
        accountingModel.value.byShelfLife = true;
        break;
      default:
        accountingModel.value.byBatch = false;
        accountingModel.value.byShelfLife = false;
        accountingModel.value.batchDisplayFormat = null;
    }
  },
});

watch(accountingMethod, method => {
  switch (method) {
    case 'noBatches':
      accountingModel.value.batchNumberRequired = false;
      accountingModel.value.batchProductionDateRequired = false;
      accountingModel.value.batchExpirationDateRequired = false;
      break;
    case 'byBatch':
      if (!accountingModel.value.batchProductionDateRequired) {
        accountingModel.value.batchNumberRequired = true;
      }
      accountingModel.value.batchExpirationDateRequired = false;
      break;
    case 'byBatchAndShelfLife':
      accountingModel.value.batchProductionDateRequired = true;
      accountingModel.value.batchExpirationDateRequired = true;
      break;
  }
});

watch(() => accountingModel.value.batchNumberRequired, numberRequired => {
  if (accountingModel.value.byBatch && !numberRequired) {
    accountingModel.value.batchProductionDateRequired = true;
  }
});

watch(() => accountingModel.value.batchProductionDateRequired, prodDateRequired => {
  if (accountingModel.value.byBatch && !prodDateRequired) {
    accountingModel.value.batchNumberRequired = true;
  }
});

const batchDisplayFormats = computed<BatchDisplayFormatEnum[]>(() => {
  const result = [];
  switch (accountingMethod.value) {
    case 'byBatch':
      if (accountingModel.value.batchNumberRequired) {
        result.push(BatchDisplayFormatEnum.BATCH_NUMBER);
      }
      if (accountingModel.value.batchProductionDateRequired) {
        result.push(BatchDisplayFormatEnum.PRODUCTION_DATE);
      }
      if (accountingModel.value.batchProductionDateRequired
        && accountingModel.value.batchNumberRequired) {
        result.push(BatchDisplayFormatEnum.PRODUCTION_DATE_AND_BATCH_NUMBER);
      }
      break;
    case 'byBatchAndShelfLife':
      if (accountingModel.value.batchProductionDateRequired
        && accountingModel.value.batchExpirationDateRequired) {
        result.push(BatchDisplayFormatEnum.PRODUCTION_DATE_AND_EXPIRATION_DATE);
      }
      if (accountingModel.value.batchExpirationDateRequired) {
        result.push(BatchDisplayFormatEnum.EXPIRATION_DATE);
      }
      if (accountingModel.value.batchProductionDateRequired
        && accountingModel.value.batchExpirationDateRequired) {
        result.push(BatchDisplayFormatEnum.PRODUCTION_DATE_AND_SHELF_LIFE);
      }
      if (accountingModel.value.batchNumberRequired
        && accountingModel.value.batchExpirationDateRequired) {
        result.push(BatchDisplayFormatEnum.BATCH_NUMBER_AND_EXPIRATION_DATE);
      }
  }
  return result;
});

watch(batchDisplayFormats, formats => {
  if (!accountingModel.value.batchDisplayFormat ||
    !formats.includes(accountingModel.value.batchDisplayFormat)
  ) {
    accountingModel.value.batchDisplayFormat = formats[0] ?? null;
  }
});

const weightAndDimensionsNotifyOptionFormatLabel   = (format: string) => t(`askForWeightAndDimensionsOption.${format}`);

const weightAndDimensionsNotifyOptionDisplayFormat = [
  AskForWeightAndDimensionsOptionEnum.ALWAYS,
  AskForWeightAndDimensionsOptionEnum.NEVER,
  AskForWeightAndDimensionsOptionEnum.ARRIVAL_ONLY,
];

const batchDisplayFormatLabel = (format: string) => t(`batchDisplayFormat.${format}`);

const batchDisplayFormatExample = computed(() => {
  const prodDate = dayjs().subtract(1, 'month').format('DD.MM.YYYY');
  const expDate = dayjs().add(1, 'year').format('DD.MM.YYYY');

  switch (accountingModel.value.batchDisplayFormat) {
    case BatchDisplayFormatEnum.BATCH_NUMBER:
      return '123456';
    case BatchDisplayFormatEnum.PRODUCTION_DATE:
      return prodDate;
    case BatchDisplayFormatEnum.PRODUCTION_DATE_AND_BATCH_NUMBER:
      return `${prodDate} - 123456`;
    case BatchDisplayFormatEnum.PRODUCTION_DATE_AND_EXPIRATION_DATE:
      return `${prodDate} - ${expDate}`;
    case BatchDisplayFormatEnum.EXPIRATION_DATE:
      return expDate;
    case BatchDisplayFormatEnum.PRODUCTION_DATE_AND_SHELF_LIFE:
      return `${prodDate} - 365 ${t('d.')}`;
    case BatchDisplayFormatEnum.BATCH_NUMBER_AND_EXPIRATION_DATE:
      return `123456 - ${expDate}`;
    default:
      return '';
  }
});

</script>

<i18n lang="yaml">
ru:
  Accounting Models: Модели учёта
  Accounting Model {name}: Модель учёта {name}
  Accounting Model is not found: Модель учёта не найдена
  Accounting method: Метод учёта
  No batches: Не учитываем партии
  By batch: Учёт по партиям
  By batch and shelf life: Учёт по партиям и срокам годности
  Batch display format: Как отображать партию
  Choose by default: Выбирать по умолчанию
  d.: дн.
  "Example: {value}": "Пример: {value}"
  Enter Batch number: Вводить номер партии
  Enter Batch arrival date: Вводить дату партии
  Enter Batch production date: Вводить дату производства для партии
  Enter Batch expiration date: Вводить дату окончания срока годности для партии
  Go to Settings: Перейти к настройкам
  Notify to fill Weight and Dimensions: Напоминать о вводе ВГХ
  Restocking strategy: Стратегия пополнения

en:
  Accounting Models: Accounting Models
  Accounting Model {name}: Accounting Model {name}
  Accounting method: Accounting method
  Accounting Model is not found: Accounting Model is not found
  No batches: No batches
  By batch: By batch
  By batch and shelf life: By batch and shelf life
  Batch display format: Batch display format
  Choose by default: Choose by default
  d.: d.
  "Example: {value}": "Example: {value}"
  Enter Batch number: Enter Batch number
  Enter Batch arrival date: Enter Batch arrival date
  Enter Batch production date: Enter Batch production date
  Enter Batch expiration date: Enter Batch expiration date
  Go to Settings: Go to Settings
  Notify to fill Weight and Dimensions: Notify to fill Weight and Dimensions
  Restocking strategy: Restocking strategy
</i18n>
