<template>
  <MaximizedDialog
    :model-value="modelValue"
    :title="t('Change Storage')"
    transition-show="slide-up"
    transition-hide="slide-down"
    @close="emit('update:modelValue', false)"
  >
    <HorizontalPropsList v-slot="{ item }">
      <component
        :is="item"
        :caption="t('Operation ID')"
      >
        {{ store.transfer.id }}
      </component>
      <component
        :is="item"
        :caption="t('Current Storage')"
        class="text-no-wrap"
      >
        {{ store.storageTo.name }}
      </component>
    </HorizontalPropsList>

    <div class="q-pa-lg col column justify-center">
      <TransferScanSecondStorageField @scan="handleScan" />
    </div>

    <template #bottom>
      <ButtonsRow
        v-slot="{ buttonProps }"
        v2
      >
        <QBtn
          v-bind="buttonProps"
          icon="mdi-close-circle-outline"
          @click="emit('update:modelValue', false)"
        >
          {{ t('Cancel') }}
        </QBtn>
      </ButtonsRow>
    </template>
  </MaximizedDialog>
</template>

<script setup lang="ts">

import MaximizedDialog from '@/components/MaximizedDialog.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import useTransferProcessStore from '@/stores/transferProcess';
import HorizontalPropsList from '@/views/Mobile/HorizontalPropsList.vue';
import TransferScanSecondStorageField
  from '@/views/Mobile/Transfer/TransferScanSecondStorageField.vue';
import { useI18n } from 'vue-i18n';

const store = useTransferProcessStore();

const { t } = useI18n();

defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'changed'): void;
}>();

function handleScan() {
  emit('update:modelValue', false);
  emit('changed');
}

</script>

<i18n lang="yaml">
ru:
  Change Storage: Изменить ячейку
  Operation ID: Номер операции
  Current Storage: Текущая ячейка

en:
  Change Storage: Change Storage
  Operation ID: Operation ID
  Current Storage: Current Storage
</i18n>
