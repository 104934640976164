<template>
  <BaseTable
    v-bind="$attrs"
    :rows="rows"
    :filter="filter"
    :filter-method="filterRows"
    :with-search="!withoutSearch"
    @refresh="refresh"
  >
    <template
      v-for="slot in Object.keys($slots)"
      #[slot]="bind"
    >
      <slot
        :name="slot"
        v-bind="bind"
      />
    </template>
  </BaseTable>
</template>

<script setup lang="ts">

import BaseTable from '@/components/BaseTable.vue';
import type { ReportOptions } from '@/types/reports';
import type { QTableColumn } from 'quasar';
import { ref } from 'vue';
import { valueMeetsFilter } from '@/helpers/reports';
import { equals } from 'ramda';

type TItem = Record<string, unknown>;

type Filter = Pick<ReportOptions, 'filter' | 'query'>;

const props = defineProps<{
  rows: TItem[];
  itemMeetsSearchString?: (item: TItem, searchString: string) => boolean;
  withoutSearch?: boolean;
}>();

const filter = ref<Filter>();

function refresh(options: ReportOptions) {
  const newFilter = {
    query: options.query,
    filter: options.filter,
  };

  // QTable сбрасывает пейджинг на первую страницу при изменении значения filter.
  // Поэтому не используем computed и меняем значение filter только при реальных изменениях фильтра.

  if (!equals(newFilter, filter.value)) {
    filter.value = newFilter;
  }
}

function filterRows(
  rows: TItem[],
  options: ReportOptions,
  cols: QTableColumn<TItem>[],
  getCellValue: (col: QTableColumn<TItem>, item: TItem) => TItem[keyof TItem],
) {
  return rows.filter(item => {
    if (!options) {
      return true;
    }

    return (props.itemMeetsSearchString?.(item, options.query) ?? true)
      && options.filter
        .every(f => valueMeetsFilter(getCellValue(cols.find(c => c.name === f.field)!, item), f));
  });
}

</script>
