<template>
  <MaximizedDialog
    :model-value="modelValue"
    transition-show="slide-up"
    transition-hide="slide-down"
    :title="t('Task')"
    @close="emit('update:modelValue', false)"
  >
    <slot name="error" />
    <div class="col scroll-y">
      <HorizontalPropsList v-slot="{ item }">
        <component
          :is="item"
          :caption="t('Created at')"
        >
          {{ formatDate(transfer.createdAt, FORMATS.DATETIME) }}
        </component>
        <component
          :is="item"
          :caption="t('Products Count')"
        >
          <slot name="product-count" />
        </component>
        <component
          :is="item"
          :caption="t('State')"
        >
          <QChip
            dense
            class="q-ma-none"
            :color="stateColor"
            text-color="black"
          >
            {{ t('transferState.' + transfer.state) }}
          </QChip>
        </component>
        <!--После WMS-1606 нужно отобразить пользователя, который последним выполнял задание -->
        <component
          :is="item"
          v-if="transfer?.user"
          :caption="t('User')"
        >
          {{
            `${ transfer.user.lastname } ${ transfer.user.firstname }`
          }}
        </component>
      </HorizontalPropsList>
      <div
        class="q-gutter-md"
      >
        <ScrollCarousel
          v-if="storages.length > 0"
          v-model="currentSlide"
          :items="storages"
          class="q-pa-sm"
          show-indicators
        >
          <template #item="{ item: storage }">
            <div class="inline column no-wrap items-center">
              <span class="text-grey-7">
                {{ t('Storage') }}
              </span>
              <QItemLabel class="text-h5">
                <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                {{ storage.storageFrom.name }} &rarr; {{ storage.storageTo.name }}
              </QItemLabel>
            </div>
          </template>
        </ScrollCarousel>
        <QList>
          <QVirtualScroll
            v-slot="{item}"
            :items="itemsForCurrentPlace"
          >
            <ProductListItem
              :key="item.id"
              :class="item.id === selectedItem?.id ? 'bg-grey-4' : ''"
              :product="getProductPackFromItem(item).product"
              :clickable="isItemClickable"
              hide-empty-photo
              @click="handleItemClick(item)"
            >
              <template #top-right>
                <QItemLabel caption>
                  <span>
                    <QChip
                      dense
                      size="sm"
                      :color="amountBadgeColor(item)"
                      square
                      class="q-mx-none"
                    >
                      {{ item.transferredAmount }}/{{ item.plannedAmount }}
                    </QChip>
                    {{ getProductPackFromItem(item).measurementUnit.shortName }}
                  </span>
                </QItemLabel>
              </template>
            </ProductListItem>
          </QVirtualScroll>
        </QList>
      </div>
    </div>
    <template
      v-if="$slots['buttons']"
      #bottom
    >
      <ButtonsRow
        v-slot="{ buttonProps }"
        v2
      >
        <slot
          name="buttons"
          v-bind="{ buttonProps }"
          :storage-from="currentStorages.storageFrom"
          :storage-to="currentStorages.storageTo"
        />
      </ButtonsRow>
    </template>
  </MaximizedDialog>
</template>

<script setup lang="ts">

import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import ScrollCarousel from '@/components/Mobile/ScrollCarousel.vue';
import ProductListItem from '@/components/ProductListItem.vue';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type {
  Cell, Container, ProductPack, Transfer, TransferItem, TransferTask, TransferTaskItem,
} from '@/graphql/types';
import { forOperationState, forTransferTask } from '@/helpers/badgeColors';
import HorizontalPropsList from '@/views/Mobile/HorizontalPropsList.vue';
import * as R from 'ramda';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import MaximizedDialog from '@/components/MaximizedDialog.vue';
import useTransferProcessStore from '@/stores/transferProcess';
import { QVirtualScroll } from 'quasar';

const i18n = useI18n();
const { t } = i18n;

const { formatDate } = useLocalizedFormatters(i18n);

const props = defineProps<{
  transfer: Transfer|TransferTask;
  modelValue: boolean;
  itemsSelectable?: boolean;
}>();

const emit = defineEmits<{
  (e: 'item-selected', value: TransferItem|null): TransferItem;
  (e: 'update:modelValue', value: boolean): void;
}>();

const store = useTransferProcessStore();

const currentSlide = ref(0);
watch(() => currentSlide.value, () => { selectedItem.value = null; });

const currentStorages = computed(() => storages.value[currentSlide.value]);

const isItemClickable = computed(() =>
  props.itemsSelectable && !!store.storageFrom && !!store.storageTo
    && store.storageFrom.id === currentStorages.value.storageFrom.id
    && store.storageTo.id === currentStorages.value.storageTo.id,
);

const selectedItem = ref<TransferItem | null>(null);

const isTransfer = computed(() => props.transfer.__typename === 'Transfer');

const stateColor = computed(() => isTransfer.value ? forOperationState(props.transfer as Transfer) : forTransferTask(props.transfer as TransferTask));

watch(selectedItem, item => {
  emit('item-selected', item);
});

function getProductPackFromItem(item: TransferItem | TransferTaskItem): ProductPack {
  return item.__typename === 'TransferItem' ? item.storageUnit.productPack : (item as TransferTaskItem).productPack;
}

function amountBadgeColor(item: TransferItem) {
  return item.transferredAmount === item.plannedAmount ? 'green-3' : 'red-3';
}

type Storages = {
  storageFrom: Container | Cell;
  storageTo: Container | Cell;
}

const storages = computed(() => {
  const storages = (props.transfer!.items ?? [])
    .flatMap(i => {
      return {
        storageFrom: i.storageFrom as Container | Cell,
        storageTo:   i.storageTo as Container | Cell,
      } as Storages;
    });

  return R.sortBy<Storages>(storage => storage.storageTo.name && storage.storageFrom.name, R.uniqBy(storage => [storage.storageTo.id, storage.storageFrom.id], storages));
});

const itemsForCurrentPlace = computed(() =>
  (props.transfer?.items ?? []).filter(i => i.storageFrom.id === currentStorages.value.storageFrom.id
  && i.storageTo!.id === currentStorages.value.storageTo.id));

function handleItemClick(item: TransferItem): void {
  store.selectItemOnCarouselById(item.id);

  selectedItem.value = selectedItem.value === item
    ? null
    : item;
}

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/selection.yaml"></i18n>

<i18n lang="yaml">
ru:
  Products Count: Товаров

en:
  Products Count: Products

</i18n>
