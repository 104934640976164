<template>
  <MaximizedDialog
    model-value
    transition-show="slide-up"
    transition-hide="slide-down"
    :title="title"
    @close="emit('close')"
  >
    <BigLoading v-if="fetching" />
    <PrimaryErrorBanner v-else-if="primaryError" />
    <SupplyDetailsCard
      v-else-if="supply"
      :supply="supply"
    />
    <slot
      v-else
      name="not-found-banner"
    />
    <template
      v-if="supply"
      #bottom
    >
      <ButtonsRow
        v-if="!fetchingArrival && isNotCompleted"
        v-slot="{ buttonProps }"
        v2
      >
        <QBtn
          v-bind="buttonProps"
          :to="{ name: ROUTES.ARRIVAL_PROCESS_BY_SUPPLY, params: { supplyId: supply.id }}"
          :loading="fetchingArrival"
          icon="mdi-play"
        >
          {{ arrivalInProgress ? t('Continue Arrival') : t('Start Arrival') }}
        </QBtn>
      </ButtonsRow>
    </template>
  </MaximizedDialog>
</template>

<script setup lang="ts">

import BigLoading from '@/components/BigLoading.vue';
import MaximizedDialog from '@/components/MaximizedDialog.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import type { Arrival, QuerySupplyArgs, Scalars, Supply } from '@/graphql/types';
import { SupplyStateEnum } from '@/graphql/types';
import ROUTES from '@/router/routeNames';
import useProductArrivalStore from '@/stores/productArrival';
import SupplyDetailsCard from '@/views/Mobile/Arrival/SupplyDetailsCard.vue';
import { gql, useQuery } from '@urql/vue';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { fillErrorsFromGraphQLError, primaryError, PrimaryErrorBanner } = useErrorHandling();

const store = useProductArrivalStore();

const props = defineProps<{
  id: Scalars['ID'];
  title: string;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const {
  data,
  fetching,
  error,
} = useQuery<{ supply: Supply }, QuerySupplyArgs>({
  query:     gql`
    query GetSupply($id: ID!) { supply(id: $id) {
      id
      createdAt
      plannedArrivalDate
      counterparty { id name }
      state
      externalId
      items {
        id
        productPack {
            id
            product { id sku }
        }
        amount
      }
    } }
  `,
  variables: computed(() => ({
    id: props.id,
  })),
});
watch(error, fillErrorsFromGraphQLError);
watch(data, data => {
  if (data?.supply) {
    supply.value = data.supply;
  }
});
const supply = ref<Supply | null>(null);

const fetchingArrival = ref(false);

const arrivalInProgress = ref<Arrival | null>(null);

watch(supply, async supply => {
  if (!supply) {
    return;
  }
  fetchingArrival.value = true;
  arrivalInProgress.value = await store.getArrivalBySupply(supply);
  fetchingArrival.value = false;
});

const isNotCompleted = computed(() => supply.value && supply.value.state !== SupplyStateEnum.COMPLETED);

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/counts.yaml"></i18n>

<i18n lang="yaml">

ru:
  Supply not found: Поступление не найдено
  Go to Supplies List: Перейти к списку поступлений
  Code: Код
  Start Arrival: Начать приёмку
  Continue Arrival: Продолжить приёмку

en:
  Supply not found: Supply not found
  Go to Supplies List: Go to Supplies List
  Code: Code
  Start Arrival: Start Arrival
  Continue Arrival: Continue Arrival

</i18n>
