<template>
  <FullHeightPage>
    <DashboardTabs storage-key="arrivalDashboard.tab">
      <template #pending>
        <ArrivalDashboardList
          :states="[ ArrivalStateEnum.IN_PROGRESS ]"
          storage-prefix="arrivalDashboard.pendingTab.listOptions"
          class="full-height scroll-y"
          @continue-arrival="startArrival($event)"
        />
      </template>
      <template #completed>
        <ArrivalDashboardList
          :states="[ ArrivalStateEnum.COMPLETED ]"
          storage-prefix="arrivalDashboard.completedTab.listOptions"
          class="full-height scroll-y"
          @continue-arrival="startArrival($event)"
        />
      </template>
    </DashboardTabs>
    <ButtonsRow
      v-slot="{ buttonProps }"
      v2
    >
      <QBtn
        v-bind="buttonProps"
        icon="mdi-plus-circle"
        @click="startArrival"
      >
        {{ t('Create') }}
      </QBtn>
    </ButtonsRow>
  </FullHeightPage>
</template>

<script setup lang="ts">

import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import { useI18n } from 'vue-i18n';
import FullHeightPage from '@/components/FullHeightPage.vue';
import DashboardTabs from '@/components/DashboardTabs.vue';
import ArrivalDashboardList from '@/views/Mobile/Arrival/ArrivalDashboardList.vue';
import type { Arrival } from '@/graphql/types';
import { ArrivalStateEnum } from '@/graphql/types';
import { useRouter } from 'vue-router';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

useBreadcrumbs(t('Arrival'));

const router = useRouter();

function startArrival(arrival?: Arrival) {
  router.push({ name: ROUTES.ARRIVAL_PROCESS, params: { id: arrival?.id } });
}

</script>

<i18n lang="yaml">
ru:
  Arrival: Приёмка

en:
  Arrival: Arrival

</i18n>
