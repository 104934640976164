<template>
  <QPage padding>
    <QCard>
      <PrimaryErrorBanner />
      <QCardSection
        v-if="fetching"
        class="text-center"
      >
        <BigLoading />
      </QCardSection>
      <template v-else-if="realization">
        <CardTitle>
          {{ t('operationType.Realization') }}
          {{
            t('{id} dated {date}', {
              id: realization.id,
              date: formatDate(realization.createdAt, FORMATS.DATETIME),
            })
          }}
          <OperationState :operation="realization" />
        </CardTitle>

        <QCardSection>
          <NonEditableField :label="t('User')">
            {{ userFullName(realization.user) }}
          </NonEditableField>
        </QCardSection>

        <BaseTable
          :rows="realization.items"
          :fields="itemsTableFields"
        >
          <template #body-cell-productSku="column">
            <BodyCellLink
              :column="column"
              :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: column.row.productPack.product.id } }"
              variant="link"
            />
          </template>
        </BaseTable>

        <QCardActions>
          <QBtn
            icon="mdi-arrow-left"
            @click="navigateBack({ name: ROUTES.REALIZATIONS_LIST })"
          >
            {{ t('Back' ) }}
          </QBtn>
        </QCardActions>
      </template>
    </QCard>
  </QPage>
</template>

<script setup lang="ts">

import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import type { QueryRealizationArgs, Realization, RealizationItem } from '@/graphql/types';
import { gql, useQuery } from '@urql/vue';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import CardTitle from '@/components/CardTitle.vue';
import BigLoading from '@/components/BigLoading.vue';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import OperationState from '@/components/OperationState.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import userFullName from '@/helpers/userFullName';
import NonEditableField from '@/components/NonEditableField.vue';
import BaseTable from '@/components/BaseTable.vue';
import type { QTableColumn } from 'quasar';
import navigateBack from '@/helpers/navigateBack';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

// noinspection LocalVariableNamingConventionJS
const { fillErrorsFromGraphQLError, PrimaryErrorBanner } = useErrorHandling();

const props = defineProps<{
  id: string;
}>();

const { data, error, fetching } = useQuery<{ realization: Realization }, QueryRealizationArgs>({
  query:     gql`
    query GetRealizationForCard($id: ID!) {
      realization(id: $id) {
        id
        createdAt
        state
        user { id firstname lastname }
        items {
          id
          shipment { id externalId }
          productPack {
            id
            product { id sku name }
          }
          amount
        }
      }
    }
  `,
  variables: computed(() => ({ id: props.id! })),
});
const realization = computed(() => data.value?.realization);

watch(error, fillErrorsFromGraphQLError);

const itemsTableFields: QTableColumn<RealizationItem>[] = [
  {
    label:  t('Order'),
    name:   'order',
    field:  item => item.shipment.externalId,
  },
  {
    label:  t('SKU'),
    name:   'productSku',
    field:  item => item.productPack.product.sku,
    align:  'left',
  },
  {
    label:  t('Product'),
    name:   'productName',
    field:  item => item.productPack.product.name,
    align:  'left',
  },
  {
    label:  t('Amount'),
    name:   'amount',
    field:  'amount',
  },
];

</script>
