<template>
  <QPage padding>
    <QCard>
      <BaseAlert
        v-if="getPrimaryError()"
        type="error"
      >
        {{ getPrimaryError() }}
      </BaseAlert>
      <QCardSection
        v-if="fetching"
        class="text-center"
      >
        <BigLoading />
      </QCardSection>
      <template v-else-if="transferTask">
        <CardTitle>
          {{
            t('{id} dated {date}', {
              id: id,
              date: formatDate(transferTask.createdAt, FORMATS.DATETIME),
            })
          }}
          <QChip
            :color="badgeColors.forTransferTask(transferTask)"
            text-color="black"
          >
            {{ t(`transferState.${transferTask.state}`) }}
          </QChip>
        </CardTitle>

        <QCardSection>
          <div class="row q-col-gutter-sm">
            <div class="col-12 col-sm-3 col-md-3 col-lg-2">
              <QField
                :label="t('User')"
                stack-label
              >
                <template #control>
                  {{ userFullName(transferTask.user) }}
                </template>
              </QField>
            </div>
            <div class="col-12 col-sm-3 col-md-3 col-lg-2">
              <QField
                :label="t('Transfers')"
                stack-label
              >
                <LinksList
                  :items="transferTask.transfers"
                  :max-to-show="5"
                >
                  <template #item="{ item }">
                    <RouterLink :to="{name: ROUTES.TRANSFERS_EDIT, params: { id: item.id }}">
                      {{ item.id }}
                    </RouterLink>
                  </template>
                </LinksList>
              </QField>
            </div>
          </div>
        </QCardSection>

        <TransferTaskItems :task="transferTask" />

        <QSeparator />

        <QCardActions>
          <QBtn
            exact
            icon="mdi-arrow-left"
            @click="navigateBack({name: 'Transfer Tasks'})"
          >
            {{ t('Back') }}
          </QBtn>

          <QSpace />

          <ConfirmsAction
            v-if="transferTask.transfers.length === 0"
            @confirmed="deleteTransferTask"
          >
            <template #title>
              {{ t('Delete Transfer Task?') }}
            </template>
            <template #activator="{prompt}">
              <QBtn
                icon="mdi-delete"
                color="red"
                :label="t('Delete')"
                :loading="deletingTransferTask"
                @click="prompt()"
              />
            </template>
          </ConfirmsAction>

          <ConfirmsAction
            @confirmed="completeTransfer"
          >
            <template #title>
              {{ t('Complete Transfer?') }}
            </template>
            <template #activator="{ prompt }">
              <QBtn
                :disable="!unfinishedTransfer"
                icon="mdi-check-all"
                :label="t('Complete')"
                @click="prompt"
              />
            </template>
          </ConfirmsAction>
        </QCardActions>
      </template>
      <BaseAlert
        v-else
        type="info"
        icon="mdi-run-fast"
      >
        <p>
          {{ t('Transfer Task not found') }}
        </p>
        <QBtn
          exact
          color="primary"
          icon="mdi-arrow-left"
          :to="{ name: 'Transfer' }"
        >
          {{ t('Go to Transfer Tasks List') }}
        </QBtn>
      </BaseAlert>
    </QCard>
  </QPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import BigLoading from '@/components/BigLoading.vue';
import CardTitle from '@/components/CardTitle.vue';
import LinksList from '@/components/LinksList.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type { TransferTask, Transfer, MutationDeleteTransferTaskArgs } from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import userFullName from '@/helpers/userFullName';
import ROUTES from '@/router/routeNames';
import { gql, useClientHandle, useMutation, useQuery } from '@urql/vue';
import { QField  } from 'quasar';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import navigateBack from '@/helpers/navigateBack';
import TransferTaskItems from '@/views/Storage/TransferTaskItems.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import { TransferStateEnum } from '@/graphql/types';
import ConfirmsAction from '@/components/ConfirmsAction.vue';

const { t } = useI18n();

useBreadcrumbs([t('Transfer Tasks'), t('Edit')]);

const { formatDate } = useLocalizedFormatters();

const { fillErrorsFromGraphQLError, getPrimaryError } = useErrorHandling();
const { client: urql } = useClientHandle();

const props = defineProps<{
  id: string;
}>();

const transferFragment = gql`
  fragment TransferTaskForCard on TransferTask {
      id
      createdAt
      state
      user { id firstname lastname }
      items {
        id
        amountInTransfers
        transferredAmount
        plannedAmount
        availableAmount
        storageFrom { id name }
        storageTo { id name }
        productPack {
          id
          deletedAt
          quantity
          product {
            id
            sku
            name
          }
          measurementUnit { id name shortName }
        }
      }
      transfers {
        id
        state
      }
  }
`;

const { data, error, fetching } = useQuery<{ transferTask: TransferTask }>({
  query: gql`
    ${transferFragment}
    query GetTransferTaskForCard($id: ID!) { transferTask(id: $id) {
     ...TransferTaskForCard
    } }
  `,
  variables: computed(() => ({
    id: props.id,
  })),
});
watch(error, fillErrorsFromGraphQLError);

const transferTask = computed(() => data.value?.transferTask ?? null);

const unfinishedTransfer = computed(() => transferTask.value?.transfers.find(t => t.state !== TransferStateEnum.COMPLETED));

async function completeTransfer() {
  const { data } = await urql.mutation<{ transfer: Transfer }>(
    gql`
      mutation CompleteTransfer($id: ID!) {
        transfer: completeTransfer(transferId: $id) {
          id
          state
        }
      }
    `,
    { id: unfinishedTransfer.value!.id },
  );

  const transfer = transferTask.value?.transfers.find( t => t.id == data!.transfer.id);
  transfer.state = data!.transfer.state;
}

const {
  executeMutation: doDeleteTransferTask,
  fetching:        deletingTransferTask,
} = useMutation<{ transferTaskId: string }, MutationDeleteTransferTaskArgs>(
  gql`
    mutation DeleteTransferTaskInTaskEdit($transferTaskId: ID!) {
      transferTaskId: deleteTransferTask(transferTaskId: $transferTaskId)
    }
  `
);

async function deleteTransferTask(): Promise<void> {
  const { error } = await doDeleteTransferTask({ transferTaskId: transferTask.value?.id });

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  navigateBack({ name: ROUTES.TRANSFER_TASKS_LIST });

}
</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/transfer.yaml"></i18n>

<i18n lang="yaml">
ru:
  Delete Transfer Task?: Удалить задание на перемещение?
  Transfer Task not found: Задание на перемещение не найдено
  Go to Transfer Tasks List: К списку заданий на перемещение
  Transfer {id} dated {date}: Перемещение {id} от {date}

en:
  Delete Transfer Task?: Delete Transfer Task?
  Transfer Task not found: Transfer Tasks not found
  Go to Transfer Tasks List: Go to Transfer Tasks List
  Transfer {id} dated {date}: Transfer {id} dated {date}
</i18n>
