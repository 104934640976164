<template>
  <SuppliesListMobile
    v-if="preferences.terminalMode"
    :supply-route="supplyRoute"
    :extra-filters="extraFilters"
    :fixed-filters="fixedFilters"
    :storage-prefix="mobileStoragePrefix"
  />
  <FullHeightPage v-else>
    <GraphQLQueryTable
      ref="report"
      :graphql-query="query"
      :fields="tableFields"
      :available-filters="availableFilters"
      :fixed-filters="fixedFilters"
      :row-is-deleted="entityIsDeleted"
      :storage-prefix="storagePrefix"
      keep-page-in-url
      :deletion-filter="deletionFilterForSoftDeletable"
      sticky-header
      class="col"
    >
      <template #body-cell-id="column">
        <BodyCellLink
          :column="column"
          :to="supplyRoute(column.row)"
        />
      </template>

      <template #body-cell-state="column">
        <QTd :props="column">
          <QChip
            :color="badgeColors.forSupplyState(column.row)"
            text-color="black"
          >
            {{ t('supplyState.' + column.value) }}
          </QChip>
        </QTd>
      </template>

      <template #after-search-string="{ options }">
        <slot
          name="import-export"
          v-bind="{ options }"
          @success="report.refresh()"
        />
      </template>
    </GraphQLQueryTable>
  </FullHeightPage>
</template>

<script setup lang="ts">

import type BaseTable from '@/components/BaseTable.vue';
import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import FullHeightPage from '@/components/FullHeightPage.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import useLocalPreferences from '@/composables/useLocalPreferences';
import {
  type ReportFilterInput,
  type Supply,
  type SupplyItem,
  SupplyStateEnum,
} from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import * as reports from '@/helpers/reports';
import {
  createDatesRangeOperator,
  deletionFilterForSoftDeletable,
  entityIsDeleted,
} from '@/helpers/reports';
import type { ReportFilter, TableColumn } from '@/types/reports';
import SuppliesListMobile from '@/views/Arrival/Supply/SuppliesListMobile.vue';
import { gql } from '@urql/vue';
import * as R from 'ramda';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import type { RouteLocationRaw } from 'vue-router';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const preferences = useLocalPreferences();

const props = withDefaults(defineProps<{
  supplyRoute: (supply: Supply) => RouteLocationRaw;
  extraFields: TableColumn<Supply>[];
  extraFilters?: ReportFilter[];
  fixedFilters: ReportFilterInput[];
  storagePrefix: string;
  mobileStoragePrefix: string;
}>(), {
  extraFilters: () => [],
});

const report = ref<InstanceType<typeof BaseTable>>(null);

const tableFields: TableColumn<Supply>[] = [
  {
    label:     t('ID'),
    name:      'id',
    field:     'id',
    mandatory: true,
    align:     'left',
    sortable:  true,
  },
  {
    label:     t('Created at'),
    name:      'createdAt',
    field:     'createdAt',
    format:    date => formatDate(date, FORMATS.DATETIME),
    align:     'left',
    sortable:  true,
  },
  {
    label:      t('Arrival Date'),
    labelTitle: t('Planned Arrival Date'),
    name:       'plannedArrivalDate',
    field:      'plannedArrivalDate',
    format:    date => formatDate(date, FORMATS.DATE),
    align:      'left',
    sortable:   true,
  },
  ...props.extraFields,
  {
    label: t('State'),
    name:  'state',
    field: 'state',
    align: 'left',
  },
  {
    label: t('Number'),
    name:  'externalId',
    field: 'externalId',
    align: 'left',
  },
  {
    label:      t('Products'),
    labelTitle: t('Unique SKUs amount'),
    name:       'products',
    field:      'items',
    format:     (items?: SupplyItem[]) => String(R.uniqBy(i => i.productPack.product.sku, items ?? []).length),
    align:      'right',
  },
];

const availableFilters: ReportFilter[] = [
  {
    field:     'createdAt',
    label:     t('Created at'),
    operators: [
      createDatesRangeOperator(),
    ],
  },
  ...props.extraFilters,
  {
    field:     'state',
    label:     t('State'),
    operators: [
      ...(['=', '!=', 'in', 'not in'] as const).map(op =>
        reports.createList(op, () => Promise.resolve([
          SupplyStateEnum.CONFIRMED,
          SupplyStateEnum.ARRIVING,
          SupplyStateEnum.COMPLETED,
        ].map(value => ({
          value,
          label: t(`supplyState.${value}`),
        }))), t(`reportFilterOperator.${op}`))),
    ],
  },
  {
    field:     'product',
    label:     t('SKU'),
    operators: [
      reports.createPrimitive('=', 'string'),
      reports.createPrimitive('in', 'multiString', {
        label: t('reportFilterOperator.in'),
      }),
    ],
  },
];

const query =
  gql`
    query GetSupplies($page: Int, $perPage: Int!, $query: String, $filter: [ReportFilterInput!], $sort: [ReportSortInput!]!) {
      result: suppliesReport(page: $page, perPage: $perPage, query: $query, filter: $filter, sort: $sort) {
        data {
          id
          createdAt
          plannedArrivalDate
          counterparty { id name }
          ... on CustomerReturn {
            orderNumber
            customerOrder { id externalId }
          }
          state
          deletedAt
          externalId
          items {
            id
            productPack {
              id
              product { id sku }
            }
          }
        }
        total
      }
    }
  `;

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/counts.yaml"></i18n>

<i18n lang="yaml">
ru:
  Planned Arrival Date: Планируемая дата поступления
  Supply number: Номер поступления
  Unique SKUs amount: Количество артикулов

en:
  Planned Arrival Date: Planned Arrival Date
  Supply number: Supply number
  Unique SKUs amount: Unique SKUs amount
</i18n>
