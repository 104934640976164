<template>
  <div
    v-if="mobile"
    class="column q-px-md"
  >
    <QSelect
      v-model="areaKind"
      :label="t('Stock Type')"
      :options="areaKindOptions"
      :option-label="optionLabel"
      :display-value="areaKind ? undefined : t('All')"
    />
    <div class="col">
      <slot name="operator" />
    </div>
    <VirtualKeyboardPolicyContext #="{ policy, shouldShowToggle }">
      <QInput
        ref="amountFieldRef"
        v-model="stockAmount"
        type="number"
        :label="t('Amount')"
        class="col-6"
        :virtualkeyboardpolicy="policy"
        autofocus
        @focus="($event.target as HTMLInputElement).select()"
        @keypress.enter="emit('save-filter')"
      >
        <template
          v-if="shouldShowToggle"
          #append
        >
          <VirtualKeyboardToggleBtn @toggle="amountFieldRef?.focus()" />
        </template>
      </QInput>
    </VirtualKeyboardPolicyContext>
    <ButtonsRow
      v-if="mobile && stockAmount !== null && Number.isFinite(parseFloat(stockAmount))"
      v-slot="{ buttonProps }"
      v2
    >
      <QBtn
        v-bind="buttonProps"
        icon="mdi-content-save"
        @click="emit('save-filter')"
      >
        {{ t('Apply') }}
      </QBtn>
    </ButtonsRow>
  </div>
  <div
    v-else
    class="row"
  >
    <QSelect
      v-model="areaKind"
      :label="t('Stock Type')"
      :options="areaKindOptions"
      :option-label="optionLabel"
      :display-value="areaKind ? undefined : t('All')"
      style="width: 150px"
      dense
    />
    <slot
      name="operator"
      :select-props="{ dense: true, style: { width: '50px' } }"
    />
    <QInput
      v-model="stockAmount"
      type="number"
      :label="t('Amount')"
      dense
      class="col-6"
      style="width: 150px"
      @keypress.enter="emit('save-filter')"
    />
  </div>
</template>

<script setup lang="ts">

import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import {
  VirtualKeyboardPolicyContext,
  VirtualKeyboardToggleBtn,
} from '@/composables/useVirtualKeyboardPolicy';
import { CellsAreaKindEnum } from '@/graphql/types';
import type { FilterOperatorString, FilterValue } from '@/types/reports';
import { QInput } from 'quasar';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

type StockFilterValue = {
  areaKind: CellsAreaKindEnum | null;
  amount: number;
};

const { t } = useI18n();

useBreadcrumbs(t('Products'));

const props = defineProps<{
  modelValue: FilterValue<StockFilterValue> | null;
  operator: FilterOperatorString;
  mobile: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: FilterValue<StockFilterValue> | null): void;
  (e: 'save-filter'): void;
}>();

const amountFieldRef = ref<InstanceType<typeof QInput>>();

const areaKind = ref<CellsAreaKindEnum | null>(null);
const stockAmount = ref<string | null>(null);

watch(() => props.modelValue, (modelValue) => {
  areaKind.value = modelValue?.value.areaKind ?? null;
  stockAmount.value = modelValue ? String(modelValue.value.amount) : null;
}, { immediate: true });

const areaKindOptions: (CellsAreaKindEnum | null)[] = [
  null,
  CellsAreaKindEnum.SELECTION,
  CellsAreaKindEnum.STORAGE,
  CellsAreaKindEnum.EXTERNAL,
  CellsAreaKindEnum.DEFECT,
];

function optionLabel(option: CellsAreaKindEnum | null) {
  if (!option) {
    return t('All');
  }

  return t('cellsAreaKind.' + option);
}

watch([areaKind, stockAmount], ([areaKind, amount]) => {
  if (amount === null || !Number.isFinite(Number.parseFloat(amount))) {
    emit('update:modelValue', null);
    return;
  }

  emit('update:modelValue', {
    label: amount,
    value: {
      areaKind,
      amount: Number.parseFloat(amount),
    }
  });
});

</script>

<i18n lang="yaml">
ru:
  Stock Type: Тип остатка
  All: Весь
en:
  Stock Type: Stock Type
  All: All
</i18n>
