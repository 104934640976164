<template>
  <div class="q-pb-md">
    <HorizontalPropsList v-slot="{ item }">
      <component
        :is="item"
        v-if="store.transfer && store.storageFrom"
        :caption="t('#')"
      >
        {{ store.transfer.id }}
      </component>
      <component
        :is="item"
        v-if="store.storageFrom"
        :caption="t('From')"
        class="text-no-wrap"
      >
        <QIcon
          v-if="isContainer(store.storageFrom)"
          name="mdi-select-group"
          size="xs"
          class="q-mr-xs"
        />
        {{ store.storageFrom.name }}
      </component>
      <component
        :is="item"
        v-if="store.storageTo"
        :caption="t('To')"
        class="text-no-wrap"
      >
        <QIcon
          v-if="isContainer(store.storageTo)"
          name="mdi-select-group"
          size="xs"
          class="q-mr-xs"
        />
        {{ store.storageTo.name }}
      </component>
      <component
        :is="item"
        v-if="store.storageFrom?.storageCell.cellsArea"
        :caption="t('From Area')"
      >
        {{ store.storageFrom.storageCell.cellsArea.name }}
      </component>
      <component
        :is="item"
        v-if="store.storageTo?.storageCell.cellsArea"
        :caption="t('To Area')"
      >
        {{ store.storageTo.storageCell.cellsArea.name }}
      </component>
    </HorizontalPropsList>
  </div>
</template>

<script setup lang="ts">

import useTransferProcessStore from '@/stores/transferProcess';
import HorizontalPropsList from '@/views/Mobile/HorizontalPropsList.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const store = useTransferProcessStore();

const isContainer = (storage: Storage): boolean => storage.__typename === 'Container';

</script>

<i18n lang="yaml">
ru:
  "#": №
  Operation: Операция
  From: Из
  To: В
  From Area: Из зоны
  To Area: В зону
en:
  "#": "#"
  Operation: Operation
  From: From
  To: To
  From Area: From Area
  To Area: To Area
</i18n>
