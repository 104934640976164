<template>
  <QDialog v-model="isOpen">
    <QCard style="width: 600px;">
      <CardTitle>
        {{ t('Create Task') }}
      </CardTitle>

      <QCardSection class="q-pt-none">
        <QSelect
          v-model="taskType"
          :options="Object.values(TaskType)"
          :option-label="taskTypeLabel"
          :label="t('Task Type')"
        />
      </QCardSection>

      <QSeparator />

      <TransferProductToSpecifiedStorageTask
        v-if="taskType === TaskType.transferToSpecifiedStorage"
        @close="isOpen = false"
        @create="handleCreated"
      />
    </QCard>
  </QDialog>
  <slot
    name="activator"
    @click="isOpen = true"
  />
</template>

<script setup lang="ts">

import CardTitle from '@/components/CardTitle.vue';
import TransferProductToSpecifiedStorageTask
  from '@/views/Mobile/TransferTask/TransferTaskForms/TransferProductToSpecifiedStorageTask.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

enum TaskType {
  transferToSpecifiedStorage = 'transferToSpecifiedStorage',
}

const { t } = useI18n();

const emit = defineEmits<{
  (e: 'created'): void;
}>();

const isOpen = ref(false);

function taskTypeLabel(type: TaskType) {
  return {
    [TaskType.transferToSpecifiedStorage]: t('Transfer Product to Specified Storage'),
  }[type];
}

const taskType = ref(TaskType.transferToSpecifiedStorage);

function handleCreated() {
  isOpen.value = false;
  emit('created');
}

</script>

<i18n lang="yaml">
ru:
  Create Task: Создать задание
  Task Type: Тип задания
  Transfer Product to Specified Storage: Перемещение товара в указанную ячейку

en:
  Create Task: Create Task
  Task Type: Task Type
  Transfer Product to Specified Storage: Transfer Product to Specified Storage
</i18n>
