<template>
  <ExcelExport
    v-if="store.can(Ability.ImportExcel)"
    :type="ExcelImportTypeEnum.TRANSFERS"
    :mutation-export="mutationExport"
    :options="options"
  />
  <ExcelImport
    :type="ExcelImportTypeEnum.TRANSFERS"
    :mutation-validate="mutationValidate"
    :mutation-import="mutationImport"
    :success-notification="n => t('Imported transfer with {n} items', n)"
    @success="emit('success')"
  >
    <template #info-for-upload-file>
      {{ t('File must contain transfer only for add (not full transfers list)') }}
    </template>

    <template #import-btn-title>
      {{ t('Import Transfer') }}
    </template>

    <template #row-with-error-message="{ rowsCount }">
      {{ t('{n} items with errors', rowsCount) }}
    </template>

    <template #validation-success="{ rowsCount }">
      {{ t('Validation success. You can create transfer with {n} items', rowsCount) }}
    </template>
  </ExcelImport>
</template>

<script setup lang="ts">

import { ExcelImportTypeEnum } from '@/graphql/types';
import type { ReportOptions } from '@/types/reports';
import ExcelImport from '@/views/ExcelImport.vue';
import { gql } from '@urql/vue';
import { useI18n } from 'vue-i18n';
import useStore from '@/stores/root';
import { Ability } from '@/types/backend';
import ExcelExport from '@/views/ExcelExport.vue';

const { t } = useI18n();
const store = useStore();

defineProps<{
  options?: ReportOptions;
}>();

const emit = defineEmits<{
  (e: 'success'): void;
}>();

const mutationImport = gql`
  mutation ImportTransfer($type: ExcelImportTypeEnum!, $file: Upload) {
    importedCount: importExcelFile(type: $type, file: $file)
  }
`;


const mutationExport = gql`
  mutation GenerateTransfersFile($type: ExcelImportTypeEnum!, $searchString: String, $filter: [ReportFilterInput!]) {
    exportFilePath: generateExcelExportFile(type: $type, searchString: $searchString, filter: $filter)
  }
`;

const mutationValidate = gql`
  mutation ValidateTransferImportFile($type: ExcelImportTypeEnum!, $file: Upload) {
    validationResult: validateExcelImportFile(type: $type, file: $file) {
      isValid
      rowsCount
      validationErrorMessage
      fileWithErrorsUrl
    }
  }
`;

</script>

<i18n lang="yaml">
ru:
  Import Transfer: Импортировать перемещение
  File must contain transfer only for add (not full transfers list): >
    Файл должен содержать только перемещение для добавления (не весь список перемещений)
  Validation success. You can create transfer with {n} items: >
    Проверка прошла успешно. Вы можете создать перемещение с {n} элементами
    | Проверка прошла успешно. Вы можете создать перемещение с {n} элементом
    | Проверка прошла успешно. Вы можете создать перемещение с {n} элементами
    | Проверка прошла успешно. Вы можете создать перемещение с {n} элементами
  "{n} items with errors": >
    {n} элементов с ошибками
    | {n} элемент с ошибками
    | {n} элемента с ошибками
    | {n} элементов с ошибками
  Imported transfer with {n} items: >
    Импортировано перемещение с {n} элементами
    | Импортировано перемещение с {n} элементом
    | Импортировано перемещение с {n} элементами
    | Импортировано перемещение с {n} элементами
en:
  Import Transfer: Import Transfer
  File must contain transfer only for add (not full transfers list): File must contain transfer only for add (not full transfers list)
  Validation success. You can create transfer with {n} items: >
    Validation success. You can create transfer with {n} item
    | Validation success. You can create transfer with {n} items
  "{n} items with errors": >
    {n} item with errors
    | {n} items with errors
  Imported transfer with {n} items: >
    Imported transfer with {n} item
    | Imported transfer with {n} items
</i18n>
