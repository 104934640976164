<template>
  <div :class="{ 'q-pa-md': mobile }">
    <VirtualKeyboardPolicyContext #="{ policy, shouldShowToggle }">
      <QInput
        ref="fieldRef"
        v-bind="$attrs"
        :model-value="modelValue ? modelValue.value : null"
        :label="t('Value')"
        dense
        bottom-slots
        :type="operator.inputType ?? 'search'"
        :suffix="operator.valueSuffix"
        :virtualkeyboardpolicy="operator.preferPhysicalKeyboard ? policy : undefined"
        autofocus
        @focus="($event.target as HTMLInputElement).select()"
        @update:model-value="onInput($event)"
        @keypress.enter="emit('save-filter')"
      >
        <template
          v-if="operator.preferPhysicalKeyboard && shouldShowToggle"
          #append
        >
          <VirtualKeyboardToggleBtn @toggle="fieldRef?.focus()" />
        </template>
      </QInput>
    </VirtualKeyboardPolicyContext>
    <ButtonsRow
      v-if="mobile && modelValue"
      v-slot="{ buttonProps }"
      v2
    >
      <QBtn
        v-bind="buttonProps"
        icon="mdi-content-save"
        @click="emit('save-filter')"
      >
        {{ t('Apply') }}
      </QBtn>
    </ButtonsRow>
  </div>
</template>

<script setup lang="ts">

import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import {
  VirtualKeyboardPolicyContext,
  VirtualKeyboardToggleBtn,
} from '@/composables/useVirtualKeyboardPolicy';
import type { FilterOperatorString, FilterValue } from '@/types/reports';
import { QInput } from 'quasar';
import { shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  modelValue: FilterValue<string> | null;
  operator: FilterOperatorString;
  mobile: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: FilterValue<string> | null): void;
  (e: 'save-filter'): void;
}>();

const fieldRef = shallowRef<QInput>();

function onInput(newValue: string): void {
  const label = newValue + (props.operator.valueSuffix ? ` ${props.operator.valueSuffix}` : '');

  emit('update:modelValue', newValue ? {
    label,
    value: newValue,
  } as FilterValue<string> : null);
}

</script>

<i18n lang="yaml">
ru:
  Value: Значение
en:
  Value: Value
</i18n>
